import { disablePageScroll, enablePageScroll } from 'scroll-lock';

function addHeigthToText (wrapperMessage, item) {
  if (wrapperMessage.hasClass('active')) {
    let height = item[0].scrollHeight; 
    item.css('height', height + 'px');
  } else {
    item.css('height', ''); 
  }
}

function thousandSeparator(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function closeNotification(button) {
  const container = $('.notification-desktop-container');
  const tabs = container.find('.nav-tabs li');
  const buttonShower = button.filter(':not(.notification-close, .js-mob-menu-btn)');
  const contentText = $('.notification-content');
  const itemText = $('.notification-text');


  if (tabs.length === 1) {
    tabs.addClass('not-border');
  }

  const isActive = buttonShower.hasClass('active');

  if (isActive) {
    toggleNotification(container, true);
  } else {
    toggleNotification(container, false);
    contentText.removeClass('active');
    itemText.removeClass('active');
    addHeigthToText(contentText, itemText);
  }

}


function toggleNotification(container, show) {
  const ulElement = container.closest('ul');
  ulElement.toggleClass('active', show);
  container.toggleClass('active', show);
}

function updateNotificationClassesFade(notificationContent) {
  let canScrollUp = notificationContent.scrollTop() > 0;
  let canScrollDown = notificationContent.scrollTop() + notificationContent.outerHeight() + 1 < notificationContent.prop('scrollHeight');

  if (canScrollUp) {
    notificationContent.addClass('faded-top');
  } else {
    notificationContent.removeClass('faded-top');
  }

  if (canScrollDown) {
    notificationContent.addClass('faded');
  } else {
    notificationContent.removeClass('faded');
  }
}

function updateNotificationCounters(notificationBody) {
  let counterNotifications = notificationBody.find('.notification').length;
  let counterGroups = notificationBody.find('.timeline-group').length;

  return {
    counterNotifications: counterNotifications,
    counterGroups: counterGroups
  };

}

function removeNotificationItem(notification, group) {
  notification.addClass('removing');
  notification.animate({
    left: '25%',
    height: 0,
    opacity: 0,
  }, 500, function () {
    $(this).remove();
    notification.removeClass('removing');
  });
  if (group) {
    let remainingNotifications = group.find('.notification').length;
    if (remainingNotifications <= 1) {
      group.animate({
        left: '25%',
        height: 0,
        opacity: 0
      }, 500, function () {
        $(this).remove();
        notification.removeClass('removing');
      });
    }
  }
}

function hideCounterNotification(type, data, container) {
  const countSelector = container.find(`a[href="#${type}"] span.count`);
  const unreadCount = Number(data[`${type}_count_unread`]);

  if (unreadCount === 0) {
    countSelector.fadeOut(300);
  }
}

function updateNotificationClassesFadeDefault(childSelector, parents) {
  parents.each(function() {
    var parentElement = $(this);
    let childElements = parentElement.find(childSelector);

    childElements.each(function() {
      var childElement = $(this);

      if (childElement.outerHeight() > parentElement.height()) {
        parents.addClass('faded');
      }
    });
  });
}

(function ($) {
  $(document).on('click', '.notification-body-content .notification-content', function(e){

    let wrapper = $(this).closest('.notification-content');

    let textItem = $(this).find('.notification-text');

    wrapper.toggleClass('active');

    textItem.toggleClass('active');

    addHeigthToText(wrapper, textItem);

  });

  $(document).on('click', '.notification-header__link', function(e) {

    $(this).find('svg').remove();

    const preloaderClass = 'btn-preloader-link';

    const preloader = $('<div>').addClass('btn-preloader');

    if (preloaderClass) {
      preloader.addClass(preloaderClass);
    }

    $(this).append(preloader);

    setTimeout(function() {
      preloader.remove();
    }, 4000);

  });

  $(".notification-footer .btn-hover").on("click", function(event) {
    event.preventDefault();
    const targetAnchor = $(this).attr("href");
    window.location.href = targetAnchor;
  });

  $(document).on('click', '.notification-open-settings', function(){
    let isActive = $(this).toggleClass('active').hasClass('active');
    $('.notification-list-settings').fadeToggle(300);
    if(isActive) {
      $('.notification-list-settings a').removeClass('active');
    }
  });

  

  $(document).on('click', '.showerNotificationsMobile', function () {


    let $this = $(this);

    $this.toggleClass('active');

    const mobileWrapperEmpty = $('.notification-item-empty').length;
    const mobileWrapperNotifications = $('.notification-mobile-container').length;

    if (mobileWrapperNotifications) {
      disablePageScroll();
      $('.js-mob-menu-btn').removeClass('active');
      $('.user-menu').removeClass('active');
      setTimeout(function(){
        $('.notification-mobile-container').addClass('active');
        $('.mini-chat-wrap, #launcher').addClass('is--hidden-mobile');
      }, 550);

      if ($(window).width() < 767) {
        
        const slickItemMobile = $('.slick-item-mobile');
  
        if (slickItemMobile.length > 0 && slickItemMobile.hasClass('slick-initialized')) {
          slickItemMobile.slick('unslick');
        }
  
        let index = $('.slick-item-mobile li.active').index();
  
        slickItemMobile.slick({
          dots: false,
          infinite: true,
          centerMode: false,
          variableWidth: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          initialSlide: index,
          nextArrow: '<a type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M13.3171 9.74188C13.3171 9.95097 13.2373 10.16 13.0779 10.3194L8.06145 15.3359C7.74234 15.655 7.22496 15.655 6.90598 15.3359C6.587 15.0169 6.587 14.4996 6.90598 14.1805L11.3448 9.74188L6.90614 5.30327C6.58716 4.98416 6.58716 4.46693 6.90614 4.14798C7.22512 3.82871 7.7425 3.82871 8.06161 4.14798L13.0781 9.16431C13.2374 9.3238 13.3171 9.53287 13.3171 9.74188Z" fill="#F6F6F6"/></svg></a>',
          prevArrow: '<a type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6.67485 9.74201C6.67485 9.53291 6.75469 9.32385 6.91402 9.16444L11.9305 4.14802C12.2496 3.82891 12.767 3.82891 13.086 4.14802C13.4049 4.467 13.4049 4.98428 13.086 5.30342L8.64711 9.74201L13.0858 14.1806C13.4048 14.4997 13.4048 15.017 13.0858 15.3359C12.7668 15.6552 12.2494 15.6552 11.9303 15.3359L6.91387 10.3196C6.75451 10.1601 6.67485 9.95102 6.67485 9.74201Z" fill="#F6F6F6"/></svg></a>',
        });
  
        slickItemMobile.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
          if(slick.slideCount < 2) {
            slickItemMobile.addClass('hide'); 
          }
        });
      }
    }else {
      $this.find('.notification-item-empty').toggleClass('active');
    }
  });

  $(document).on('click', '.notification-close', function(){

    const contentText = $('.notification-content');
    const itemText = $('.notification-text');

    $(this).closest('.notification-wrap').removeClass('active');
    $('.showerNotificationsMobile').removeClass('active');
    $('.mini-chat-wrap, #launcher').removeClass('is--hidden-mobile');
    contentText.removeClass('active');
    itemText.removeClass('active');
    //$('body').removeClass('noscroll');
    addHeigthToText(contentText, itemText);

    enablePageScroll();

  });

  $(document).on('click', '#open-notification', function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    closeNotification($(this));

    if($(this).hasClass('active')) {
      $('.background-page').addClass('active');
      //$('body').addClass('noscroll');
      disablePageScroll();
    }else {
      $('.background-page').removeClass('active');
      enablePageScroll();
    }
  });

  $('.tabs-indicator-notification').tabsIndicator({'hideMobile': true});

  $(document).on('click', '.nav-tabs a[data-toggle=tab]', function (e) {
    updateNotificationClassesFadeDefault($('.timeline-group'), $('.js-fade-added'));
  });

  $('.js-fade-added').on('scroll', function(){
    updateNotificationClassesFade($(this));
  });

  $(document).on('click', '.close-mobile-js-btn-preloader-gray-onClick', function(){
    $(this).closest('.notification-wrap').removeClass('active');
    $('.showerNotificationsMobile').removeClass('active');
    $('.mini-chat-wrap, #launcher').removeClass('is--hidden-mobile');
  });

  $(document).on('click', '.close-desktop-js-btn-preloader-gray-onClick', function(){
    setTimeout(function(){
      $('.btn-preloader').remove();
    }, 500);
    $('#open-notification').trigger('click');
  });

  $(document).on('click', '.notification-desktop-container .remove-notifications', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let $this = $(this);
    let notification = $this.closest('.notification');
    let notificationBody = $this.closest('.notification-body');
    let group = notification.closest('.timeline-group');
    let getItemParent = $this.closest('.notification-wrap');

    let getTotalNotifications = getItemParent.find('.notification-body .notification').length;

    $.ajax({
      url: $this.data('remove-href') + '/' + notification.attr('data-id'),
      success: function (data) {
        let dataParce = $(data.html);
        let getAttrKey = dataParce.attr('data-ts');

        let added = false;

        group.find('.notification').each(function () {
          if ($(this).attr('data-ts') === getAttrKey) {
            $(this).append(dataParce.children().last());
            added = true;
            return false;
          }
        });

        if (!added) {
          notificationBody.find('.notification-body-content').append(dataParce);
        }

        removeNotificationItem(notification, group);

        let counters = updateNotificationCounters(notificationBody);

        if (counters.counterNotifications <= 1) {
          let getTabs = getItemParent.find('.nav-tabs');
          let activeTab = getTabs.find('li.active');
        
          if (activeTab.length > 0) {
            let inactiveTab = activeTab.siblings('li:not(.active)').first();
        
            let inactiveGroup = inactiveTab.find('a').attr('data-group');
        
            $('.notification-body').each(function() {
              let group = $(this).attr('data-group');
              $(this).toggle(group === inactiveGroup);
            });
        
            getTabs.find('li').removeClass('active');
            inactiveTab.addClass('active');
            inactiveTab.find('a').removeClass('active');
            inactiveTab.find('a').trigger('click');
        
            //TODO if need delete inactive tabs
            getTabs.find('.indicator').remove();
            activeTab.remove();
          }
        }
      }
    }).done(function(){
      getTotalNotifications = getItemParent.find('.notification-body .notification').length;
      if(getTotalNotifications <= 1) {
        getItemParent.removeClass('active');
        $('.background-page').removeClass('active');
        $('#open-notification').removeClass('active');
        $('.notification-item-empty').addClass('notification-desktop-container');
        setTimeout(function(){
          getItemParent.remove();
        }, 300);
      }    
    });
  });

  $(document).on('click', '.notification-mobile-container .remove-notifications', function(e){
    e.preventDefault();
    e.stopPropagation();

    let $this = $(this);
    let notification = $this.closest('.notification');
    let notificationBody = $this.closest('.notification-body');
    let group = notification.closest('.timeline-group');
    let getItemParent = $this.closest('.notification-wrap');
    let getTotalNotifications = getItemParent.find('.notification-body .notification').length;

    $.ajax({
      url: $this.data('remove-href') + '/' + notification.attr('data-id'),
      success: function (data) {
        let dataParce = $(data.html);
        let getAttrKey = dataParce.attr('data-ts');

        let added = false;

        group.find('.notification').each(function () {
          if ($(this).attr('data-ts') === getAttrKey) {
            $(this).append(dataParce.children().last());
            added = true;
            return false;
          }
        });

        if (!added) {
          notificationBody.find('.notification-body-content').append(dataParce);
        }        

        removeNotificationItem(notification, group);

        let counters = updateNotificationCounters(notificationBody);

        if (counters.counterNotifications <= 1) {
          let slider = $('.slick-item-mobile');

          if (slider.length && slider.hasClass('slick-initialized')) {
            slider.slick('slickNext');

            slider.on('afterChange', function(e, slick, currentSlideIndex) {
              slider.addClass('hide');
            });
          }
        }
        
      }
    }).done(function(){
      getTotalNotifications = getItemParent.find('.notification-body .notification').length;
      if(getTotalNotifications <= 1) {
        getItemParent.removeClass('active');
        $('.notification-empty-mobile').addClass('notification-mobile-container');
        $('.showerNotificationsMobile').removeClass('active');
        $('.mini-chat-wrap, #launcher').removeClass('is--hidden-mobile');
        setTimeout(function(){
          getItemParent.remove();
        }, 300);
      }

    });

  });
  

  $(document).on('mouseenter', 'header .notification-wrap .notification', function () {
    let element = this;
    let newContainer = $('.notification-wrap').filter(':not(.notification-item-empty)');

    if ($(element).is('.unread')) {
      $.ajax({
          url: newContainer.attr('data-url') + '/' + $(element).attr('data-id'),
          success: function (data) {
            if (data['success'] == 1) {

              ['main', 'inbox'].forEach(type => {
                hideCounterNotification(type, data, newContainer);
              });

              $(element).removeClass('unread');
              $(element).addClass('read');

              $('.notification-wrap a[href="#main"] span.count').html(thousandSeparator(data['main_count_unread']));
              $('.notification-wrap a[href="#inbox"] span.count').html(thousandSeparator(data['inbox_count_unread']));
              $('.header-inner__user-informer').parent().find('.count-notification').html(thousandSeparator(data['count_unread']));
              if (data['count_unread'] < 1) {
                $('.header-inner__user-control--notification').removeClass('view-informer');
              }
            }
          }
      });
    }
  });

  if($(window).width() > 767) {
    $(document).on('mouseup', function(e) {
      const modal = $('.notification-wrap');
      const button = $('.header-inner__user-control--notification #open-notification');
      if (!modal.is(e.target) && modal.has(e.target).length === 0 && !button.is(e.target) && button.has(e.target).length === 0) {
        $('#open-notification').removeClass('active');
        closeNotification($(this));
      }
    });
  }

  

  $(document).on('touchstart', '.notification-list-settings li', function() {
    $(this).addClass('active');
  }).on('touchend', function() {
    $(this).removeClass('active');
    $('.notification-list-settings').fadeOut(300);
  });

  $(document).on('click', '.read-all', function(){
    $(this).each(function(){
      let me = $(this);
      let findContainer = me.closest('.notification-wrap');
      let newContainer = findContainer.filter(':not(.notification-item-empty)');
      let groupId = newContainer.find('ul.notification-tabs .active a.notification-tabs-link').data('group');
      $.ajax({
        url: me.data('href'),
        data: {
          group: groupId
        },
        success: function (data) {
          if (data['success'] == 1) {
            let alertMark = $(`.notification-body[data-group=${groupId}]`).find('.notification-alert-mark');

            $(`.notification-body[data-group=${groupId}] .notification`).each(function(){
              $(this).removeClass('unread');
              $(this).addClass('read');
            });

            ['main', 'inbox'].forEach(type => {
              hideCounterNotification(type, data, newContainer);
            });

            $(`.notification-body[data-group=${groupId}]`).find('.notification-alert-mark').addClass('active');

            $('.notification-wrap a[href="#main"] span.count').html(thousandSeparator(data['main_count_unread']));
            $('.notification-wrap a[href="#inbox"] span.count').html(thousandSeparator(data['inbox_count_unread']));

            $('.header-inner__user-informer').parent().find('.count-informer').html(thousandSeparator(data['count_unread']));
            if (data['count_unread'] < 1) {
              $('.header-inner__user-informer').removeClass('view-informer');
            }

            if(alertMark) {
              alertMark.addClass('active');
              $('.mobile-footer').addClass('alert-open');
              setTimeout(function(){
                alertMark.removeClass('active');
                alertMark.on('transitionend', function() {
                  alertMark.remove();
                });
                $('.mobile-footer').removeClass('alert-open');        
              }, 1500);
            }
          }
          
        }
      });
    });
    return false;
  });

  $('.slick-item-mobile').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    let currentSlideElement = $(slick.$slides[currentSlide]);
    let nextSlideItem = $(slick.$slides[nextSlide]);
    
    let linkSlideItem = $(nextSlideItem).find('a').attr('href');

    $(currentSlideElement).find('li').removeClass('active');
    $(nextSlideItem).find('li').addClass('active');

    $('.notification-body').each(function(index, item) {
      $(item).removeClass('active');
      
      if (linkSlideItem) {
          let targetId = linkSlideItem.split('#')[1];
          
          if ($(item).attr('id') == targetId) {
              $(item).addClass('active');
          }
      }
  });
  
    return false;
  });


  $(document).mouseup(function (e) {
    let notificationWrapper = $(".notification-mobile-container");
    if (!notificationWrapper.is(e.target) && notificationWrapper.has(e.target).length === 0) {
      notificationWrapper.removeClass('active');
      $('.showerNotificationsMobile').removeClass('active');
      $('.mini-chat-wrap, #launcher').removeClass('is--hidden-mobile');
    }
  });

})(jQuery);