import $ from 'jquery';
import localization from './i18n/localization';
import { renderAlert } from '../parts/alerts.js';

function modalCenter (parent) {
    let margin = parent.height()/2*(-1)
    if(parent.height()>$(window).height()){
        margin = $(window).height()/2*(-1)
    }
    parent.css('margin-top',margin)
}

function FileUploader(containerSelector) {
    this.container = $(containerSelector);
    this.locale = $('html').attr('lang') || 'en';
    //fallback
    // if(typeof localization[self.locale] === 'undefined') {
    //     this.locale = 'en';
    // }
    this.backendUrl = this.container.data('upload-url');
    this.deleteUrl = this.container.data('delete-url');
    this.minFiles = this.container.data('min-files') || 1;
    this.maxFiles = this.container.data('max-files') || 5;
    this.appendMode = this.container.data('append-mode') || false;
    this.currentFiles = 0;  // uploaded files atm
    this.formFieldName = this.container.data('form-field-name');
    this.inputName = this.container.data('input-name');
    this.MAX_FILE_SIZE = this.container.data('max-size') || 10 * 1024 * 1024;
    this.ALLOWED_TYPES = this.container.data('ext') || ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    this.externalFieldClass = this.container.data('external-field-class') || false;

    this.init();
}

FileUploader.prototype.init = function () {
    const self = this;

    const fileInput = this.container.find('.choise-input');
    const inputName = this.container.data('input-name');
    const progressBar = $('<div>', {class: 'progress-bar'}).hide();
    const progressStatus = $('<div>', {class: 'progress-status'});
    const cancelButton = $('<button>', {text: 'Cancel', class: 'cancel-button'}).hide();

    progressBar.append(progressStatus);
    this.container.append(fileInput, progressBar, cancelButton);
    fileInput.on('change', function (e) {
        const files = e.target.files;

        if (files.length < self.minFiles || files.length + self.currentFiles > self.maxFiles) {
            renderAlert(localization[self.locale]['incorrectNumberOfFiles'], 'danger', 6000)
            return;
        }

        if (!self.appendMode) {
            // Reset previous uploads
            self.currentFiles = 0;
            self.container.find('.file-info').remove();
        }

        Array.from(files).forEach(file => {
            if(self.isValidFile(file)) {
                self.uploadFile(file);
            }
        });
        
        fileInput.val('');
    });

    const dropZone = this.container;

    dropZone.on('dragover', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).addClass('dragging');
    });

    dropZone.on('dragleave', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).removeClass('dragging');
    });

    dropZone.on('drop', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).removeClass('dragging');

        const files = e.originalEvent.dataTransfer.files;
        if ((files.length < self.minFiles || files.length + self.currentFiles > self.maxFiles) && files.length != 0) {
            renderAlert(localization[self.locale]['incorrectNumberOfFiles'], 'danger', 6000)
            return;
        }

        if (!self.appendMode) {
            self.currentFiles = 0;
            self.container.find('.file-info').remove();
        }

        Array.from(files).forEach(file => {
            if(self.isValidFile(file)) {
                self.uploadFile(file);
            }
        });
    });
};

FileUploader.prototype.uploadFile = function (file) {
    const self = this;

    const fileContainer = this.createFileInfoBlock(file);
    let isDuplicate = this.isDuplicateFile(file)

    const loadProgress = fileContainer.find('.load-progress');
    const fileInfo = fileContainer.find('.file-info');
    const clearButton = fileContainer.find('.clearButtonMultiple');

    const formData = new FormData();
    formData.append(self.formFieldName, '');
    formData.append(self.formFieldName, file);
    
    const hiddenFieldsContainer = self.container.find('.hidden-fields');
    if (hiddenFieldsContainer.length) {
        hiddenFieldsContainer.find('input[type="hidden"]').each(function() {
            formData.append($(this).attr('name'), $(this).val());
        });
    }
    
    if(self.externalFieldClass !== false) {
        $('.' + self.externalFieldClass).each(function() {
            formData.append($(this).attr('name'), $(this).val());
        });
    }
    
    self.container.trigger('fileUploadStarted', [file.name]);

    const ajaxRequest = $.ajax({
        url: self.backendUrl,
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        xhr: function () {
            const xhr = $.ajaxSettings.xhr();
            xhr.upload.onprogress = function (e) {
                if (e.lengthComputable) {
                    const percentComplete = (e.loaded / e.total) * 100;
                    loadProgress.stop().animate({width: percentComplete + '%'}, 100);
                }
            };
            return xhr;
        },
        success: function (response) {
            if(response.success) {
                self.currentFiles++;
                self.toggleUploadButton();
                
                self.triggerFileUploaded(response.fileName);

                fileInfo.find('.success-load').show();
                fileInfo.css('opacity', '1');

                if (loadProgress.width() < loadProgress.parent().width()) {
                    loadProgress.stop().animate({width: '100%'}, 500, function () {
                        setTimeout(function () {
                            loadProgress.fadeOut(1000);
                        }, 1000);
                    });
                } else {
                    setTimeout(function () {
                        loadProgress.fadeOut(500);
                    }, 1000);
                }
                
                if(response.fileName) {
                    const fileSrc = $('<div>', {class: 'file-src'})
                            .append($('<input>', {type: 'hidden', name: self.inputName, value: response.fileName}));
                    fileContainer.append(fileSrc);                
                
                    fileContainer.attr('data-fileName', response.fileName);
                }
                if(isDuplicate){
                    renderAlert(localization[self.locale]['duplicateFile'], 'danger', 6000)
                    self.deleteFileFromServer(fileContainer, file, false);
                    setTimeout(()=>{
                        fileContainer.slideUp(400, function() {
                            $(this).remove();
                        });
                        self.currentFiles--;
                        self.toggleUploadButton();
                        self.triggerFileDeleted(response.fileName);
                    },500)
                    return;
                }

                clearButton.off('click').on('click', function () {
                    self.deleteFileFromServer(fileContainer, file, true);
                    fileContainer.slideUp(400, function() {
                        $(this).remove();
                    });
                    self.currentFiles--;
                    self.toggleUploadButton();
                    self.triggerFileDeleted(response.fileName);
                });
            } else {
                self.toggleUploadButton();
                let errorMessage = 'Error uploading file.';
                if (response && response.errors && response.errors.length) {
                    errorMessage = response.errors.join(", ");
                } else if (response && response.message) {
                    errorMessage = response.message;
                }

                self.displayErrorForContainer(fileContainer, errorMessage);
                loadProgress.width('0%');
                loadProgress.fadeOut(1000);
            }

        },
        error: function (response) {
            self.currentFiles--;
            self.toggleUploadButton();
            fileInfo.find('.success-load').hide();

            let errorMessage = localization[self.locale]['errorUploading'];

            self.displayErrorForContainer(fileContainer, errorMessage);
            loadProgress.width('0%');
        }
    });

    clearButton.on('click', function () {
        if (ajaxRequest.readyState !== 4) {
            ajaxRequest.abort();
            self.currentFiles--;
            self.toggleUploadButton();
            loadProgress.width('0%');
            fileInfo.find('.error-load').show();
            fileContainer.remove();
            modalCenter(fileContainer.closest('.case-file-upload-modal'))
        }
    });
};

FileUploader.prototype.isDuplicateFile = function(file) {
    const fileId = `${file.name}-${file.size}-${file.lastModified}`;
    if (this.uploadedFilesIds && this.uploadedFilesIds.includes(fileId)) {
        return true;
    }
    this.uploadedFilesIds = this.uploadedFilesIds || [];
    this.uploadedFilesIds.push(fileId);
    return false;
};

FileUploader.prototype.createFileInfoBlock = function (file) {
    let fileName = file.name;
    const fileExtension = file.name.split('.').pop();
    if (fileName.length > 20) {
        fileName = fileName.slice(0, 20) + '...' + fileExtension;
    }
    const fileInfo = $('<div>', {class: 'file-info', style: 'opacity: 0.5;'})
            .append($('<div>', {class: `file-extension ${fileExtension}`}))
            .append($('<div>', {class: 'file-name'})
                    .append($('<span>', {class: 'file'}).text(fileName))
                    .append($('<div>', {class: 'success-load'}).text(localization[this.locale]['successUpload']).hide())
                    .append($('<div>', {class: 'error-load'}).text(localization[this.locale]['invalidFile']))
                    )
            .append($('<input>', {type: 'button', value: '', id: 'clearButtonMultiple', class: 'clearButton clearButtonMultiple'}).click(function () {
                self.currentFiles--;
                $(this).parent().remove();
            }));

    const loadProgress = $('<div>', {class: 'load-progress', style: 'width: 0%;'})

    const fileContainer = $('<div>', {class: 'file-container file-container__tickets-modal col-md-6', style: 'display: none;'})
            .append(fileInfo)
            .append(loadProgress);

    this.container.append(fileContainer);
    fileContainer.slideDown(400);
    return fileContainer;
};


FileUploader.prototype.deleteFileFromServer = function (fileContainer, file, isRemove) {
    const self = this;
    const fileName = fileContainer.attr('data-fileName');
    const fileId = `${file.name}-${file.size}-${file.lastModified}`;
    if (this.uploadedFilesIds && this.uploadedFilesIds.includes(fileId) && isRemove) {
        this.uploadedFilesIds = this.uploadedFilesIds.filter(id => id !== fileId);
    }
    if (!fileName) {
        console.error("File name is not available!");
        return;
    }

    $.ajax({
        url: self.deleteUrl,
        type: 'POST',
        data: {fileName: fileName},
        success: function () {
            // File deleted from server successfully
        },
        error: function () {
            console.log(localization[self.locale]['errorDeletingFromServer']);
        }
    });
};

FileUploader.prototype.toggleUploadButton = function () {
    let uploadButton = this.container.find('.drag-and-drop__wrap');
    if(uploadButton.length == 0){
        uploadButton = this.container.find('.drag-and-drop__dispute')
    }

    if (this.currentFiles >= this.maxFiles) {
        uploadButton.addClass('dnd-hiden')
    } else {
        uploadButton.removeClass('dnd-hiden')
    }
    modalCenter(uploadButton.closest('.case-file-upload-modal'))
};

FileUploader.prototype.isValidFile = function(file) {
    const self = this;

    if (file.size > self.MAX_FILE_SIZE) {
        const maxSizeMB = (self.MAX_FILE_SIZE / (1024 * 1024)).toFixed(0);
        const errorMsg = localization[self.locale]['fileTooBig'].replace('{maxSize}', maxSizeMB);
        self.displayError(file.name, errorMsg);
        return false;
    }
    if (self.ALLOWED_TYPES.indexOf(file.type) === -1) {
        const allowedTypesReadable = self.ALLOWED_TYPES.map(type => type.split('/')[1].toUpperCase()).join(', ');
        const errorMsg = localization[self.locale]['invalidFileType'].replace('{allowedTypes}', allowedTypesReadable);
        self.displayError(file.name, errorMsg);
        return false;
    }
    return true;
};

FileUploader.prototype.displayErrorForContainer = function(fileContainer, errorMessage) {
    const fileInfo = fileContainer.find('.file-info');
    fileInfo.find('.error-load').text(errorMessage).show();
    setTimeout(function() {
        fileContainer.fadeOut(function() {
            $(this).remove();
        });
    }, 5000);
};

FileUploader.prototype.displayError = function(filename, errorMessage) {
    const fileContainer = this.createFileInfoBlock({name: filename});
    this.displayErrorForContainer(fileContainer, errorMessage);
};

FileUploader.prototype.getValidFilesCount = function() {
    return this.currentFiles;
};

// Events
FileUploader.prototype.triggerFileUploaded = function(fileData) {
    this.container.trigger("fileUploaded", [fileData]);
};

FileUploader.prototype.triggerFileDeleted = function(fileData) {
    this.container.trigger("fileDeleted", [fileData]);
};
export default FileUploader;