import { sharerInit } from "../plugins/sharer.js";

let isSocialSharerLoaded = false;

function socialSharerLoad() {
  // if (isSocialSharerLoaded) {
  //   return;
  // }

  $(".js-social-share-item").each(function () {
    let socialBlock = $(this).parents(".js-social-share-block");
    let customUrl = socialBlock.attr("custom-data-url");
    if (customUrl === "") {
      let url = window.location.href;
      $(this).attr("data-url", url);
    } else {
      $(this).attr("data-url", customUrl);
    }
  });

  sharerInit();

  $(document).on('click', '.js-social-share-item-tg-mobile', function(e) {
    e.preventDefault();
  
    const link = $(this).data('url');
    if (link) {
      const telegramBaseUrl = 'https://t.me/share/url?';
      const encodedLink = encodeURIComponent(link);
      const telegramUrl = `${telegramBaseUrl}url=${encodedLink}`;
  
      window.open(telegramUrl, '_blank');
    }
  });

  isSocialSharerLoaded = true;
}

export { socialSharerLoad };
