(function ($) {
    let methods = {
        // Wrap class
        wrapClass: '.custom-alerts',

        // Step to hide the next alert
        autoHideStep: 5000,

        // Step to hide the next alert
        addWidth: 8,

        viewInList: 3,

        init: function (options) {
            $(methods.wrapClass).animate({
                opacity: 1,
            }, 500);

            let zIndex = this.length;
            let width = $(methods.wrapClass).width();
            let s = 0;
            this.each(function () {
                let alert = this;

                if (s > methods.viewInList) {
                    $(alert).hide();
                }

                $(alert).css('z-index', zIndex);
                $(alert).css('width', width);
                zIndex--;

                $(alert).find('.close').on('click', function () {
                    closeAlert(alert);
                });

                $(alert).on('mouseenter', function () {
                    $(alert).addClass('showAlert');
                });
        
                $(alert).on('mouseleave', function () {
                    $(alert).css('transform', 'translateY(200%)');
                    setTimeout(function () {
                        $(alert).removeClass('showAlert');
                        closeAlert(alert);
                    }, 2000);
                });

                width += methods.addWidth;
                s++;
            });
            let firstAlert = $(methods.wrapClass).find(' .custom-alert:first-child');
            updateHeight(firstAlert);
            initAlert(firstAlert);
        },
    };

    let initAlert = function (alert) {
        $(alert).css('transform', 'translateY(0)')
        $(alert).find('.close-informer').animate({ left: '100%' }, methods.autoHideStep - 100, function () {
            let s = 0;
            let width = $(methods.wrapClass).width();
            $(methods.wrapClass).find('.custom-alert').each(function () {
                let alert = this;

                if (s <= methods.viewInList + 1) {
                    $(alert).show();
                }

                $(alert).css('width', width);

                if (s > 0) {
                    width += methods.addWidth;
                }
                s++;
            });
            if(!$(alert).hasClass('showAlert')){
                $(alert).css('transform', 'translateY(200%)');
                setTimeout(closeAlert(alert), 2000);
            }
        });
    };

    let closeAlert = function (alert) {
        let nextAlert = $(methods.wrapClass).find('.custom-alert:nth-child(2)');
        updateHeight(nextAlert);

        if(!$(alert).hasClass('showAlert')){
            $(alert).animate({ opacity: 0 }, 100, function () {
                $(alert).hide();
                $(alert).remove();
                initAlert(nextAlert);
            });
        }
    };

    let updateHeight = function (alert) {
        let realHeight = $(alert).find('.custom-alert-label').height() + 31;
        $(methods.wrapClass).find('.custom-alert').animate({ height: realHeight }, 100);
    };

    jQuery.fn.customAlert = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method named ' + method + ' does not exist for jQuery.customAlert');
        }
    };
})(jQuery);
