export function renderAlert(text, colorClass, duration) {
    const alertId = `alert-${new Date().getTime()}`;
    const alertHtml = `
        <div id="${alertId}" class="custom-alerts" style="display: none">
            <div class="${colorClass} alert">
                <img class="custom-alert-img" src="/img/notifications/${colorClass}.svg" alt="">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                ${text}
                <div class="close-informer"></div>
            </div>
        </div>
    `;

    $('body').append(alertHtml);
    $(`#${alertId}`).fadeIn();
    $(`#${alertId} .close-informer`).animate({
        width: '100%',
    }, duration, function () {
        if(!$('.custom-alerts').hasClass('showAlert')){
            $('#' + alertId).find('.alert').css('bottom', '-100px');
            setTimeout(()=>{
                $('#' + alertId).fadeOut(function() {
                    $(this).remove();
                });
            },1000) 
        }
    });
}
