import { renderAlert } from '../parts/alerts.js';

function sendMessage(objectInput) {
    $('.send-message').removeClass('active');
    var message = $(objectInput).val();
    var conversation = $(objectInput).parents('.chat-footer').find('.send-message-chat').attr('data-id');

    $(objectInput).prop('style', false);

    var data = {
        message: message,
        conversation: conversation
    };

    if($(objectInput).hasClass('mini-input')){
        var $minichatFooter = parseInt($('.messages-block .chat-footer').height(), 10);
        $(objectInput).closest('.messages-block').find('.messages-list-mobi').css('height', 465 - $minichatFooter + 'px');
    }

    $(objectInput).val('')
    $.ajax({
        url: $('.send-message-chat').attr('data-href'),
        type: "POST",
        data: data
    });
}

function reInitMessagesHeight() {
    var heightvar 
        parseInt($('.mini-chat-wrap .body .messages-block .header').innerHeight()) +
        parseInt($('.mini-chat-wrap .body .messages-block .sub-header').innerHeight()) +
        parseInt($('.mini-chat-wrap .body .messages-block .templates-messages').innerHeight()) +
        parseInt($('.mini-chat-wrap .body .messages-block .chat-footer').innerHeight());
}

function openModalChat(hidePreloader) {
    $.ajax({
        'url': $('.mini-chat-wrap .mini-icon').attr('data-contact-list'),
        'type': 'get',
        'success': function (data) {

            if (hidePreloader) {
                $('.mini-chat-wrap .preload-wrap').animate({
                    opacity: 0
                }, 500, function () {
                    $(this).css('display', 'none');
                });
            }

            $('.mini-chat-wrap .body .mini-chat .contact-list .contact-list').html(data);
        }
    });
    $('.mini-chat-wrap').addClass('active');
}

function openConversation(id) {
    $('.mini-chat-wrap .body .mini-chat .messages-block').html('');

    $('.mini-chat-wrap .preload-wrap').css('display', 'flex');
    $('.mini-chat-wrap .preload-wrap').animate({
        opacity: 1
    }, 500);
    $.ajax({
        'url': $('.mini-chat-wrap .body .mini-chat .contact-list .contact-list').attr('data-messages-list'),
        'type': 'get',
        'data': "chatId=" + id,
        'success': function (data) {

            $('.mini-chat-wrap .body .messages-block').animate({
                left: 0
            }, 500, function () {
                $(".mini-chat-wrap .open-conversation[data-id=" + id + "] .user-info .count").removeClass('active');
            });

            $('.mini-chat-wrap .body .mini-chat .messages-block').html(data);
            $('.mini-chat-wrap .preload-wrap').animate({
                opacity: 0
            }, 500, function () {
                $(this).css('display', 'none');
            });

            reInitMessagesHeight();
            $('.messages-list-mobi').scrollTop($('.messages-list-mobi').prop("scrollHeight"));
        }
    });
}

function requestForChatInput(inputField) {
    var input = $(inputField).val().toLowerCase();

    var roomID = $("#big-chat .active-room").attr("data-id");
    var data = "active-conversation=" + roomID;
    if (input != '' && input.length > 2) {
        var data = data + '&type=big&search-username=' + input;
    }

    $('.grid-preloader').addClass('active');

    $.ajax({
        url: $('.contact-list.big').attr('data-url'),
        type: "GET",
        data: data,
        success: function (data) {
            $('.grid-preloader').removeClass('active');
            $('.contact-list.big .all-height').html(data);
            if (input != '' && input.length > 2) {
                $('.foundContacts span').html($('.contact-list.big .all-height a').length);
                $('.foundContacts').show();
            } else {
                $('.foundContacts').hide();
            }
        }
    });
}

function requestForMiniChatInput(inputField) {
    var input = $(inputField).val().toLowerCase();
    if (input != '' && input.length > 2) {
        var data = data + '&search-username=' + input;
    }
    $.ajax({
        url: $('.mini-chat input').attr('data-url'),
        type: "GET",
        data: data,
        success: function (data) {
            $('.contact-list.mini').html(data);
            $('.contact-list.mini').each(function () {
                if ($(this).find('a').length) {
                    $(this).find('.no-results').hide()
                } else {
                    $(this).find('.empty').hide()
                    $(this).find('.no-results').show()
                }
            })
        }
    });
}

window.prepareOpenModalChat = function (href) {
    $('.mini-chat-wrap .preload-wrap').css('display', 'flex');
    $('.mini-chat-wrap .preload-wrap').css('opacity', 1);

    $('.mini-chat .messages-block').html('');
    $('.mini-chat .messages-block').css('left', '320px');
    $('.mini-chat-wrap').removeClass('active');
    openModalChat(false);
    $.ajax({
        'url': href,
        'type': 'get',
        'success': function (data) {
            if (data != '') {
                openModalChat(false);
                openConversation(data);
            } else {
                $('.mini-chat-wrap .preload-wrap').animate({
                    opacity: 0
                }, 500, function () {
                    $(this).css('display', 'none');
                });
            }
        }
    });
}

$(document).ready(function () {
    var chatUserInput = $('.users-search input'), timeOutUserChat;

    chatUserInput.on('keyup', function () {
        clearTimeout(timeOutUserChat);
        timeOutUserChat = setTimeout(requestForChatInput, 300, $(this));
    });

    $('.contact-list .all-height').on('DOMSubtreeModified', function () {
        $('.contact-list .all-height').each(function () {
            if ($(this).find('a').length) {
                $(this).closest('.all-height').removeClass('empty-block')
                $(this).closest('.contact-list').removeClass('not-found')
            } else {
                $(this).closest('.all-height').addClass('empty-block')
                $(this).closest('.contact-list').addClass('not-found')
            }
        })
    })

    chatUserInput.on('keydown', function () {
        clearTimeout(timeOutUserChat);
    });

    var chatMiniUserInput = $('.mini-chat input'), timeOutUserMiniChat;
    chatMiniUserInput.on('keyup', function () {
        clearTimeout(timeOutUserMiniChat);
        timeOutUserMiniChat = setTimeout(requestForMiniChatInput, 300, $(this));
    });

    chatMiniUserInput.on('keydown', function () {
        clearTimeout(timeOutUserMiniChat);
    });

    $('body').on('keyup', '.messageInput', function (event) {
        if ($(this).val().length > 0) {
            $(this).parents('.chat-footer').find('.send-message').addClass('active');
            this.style.height = (this.scrollHeight) + "px";
        } else {
            $(this).parents('.chat-footer').find('.send-message').removeClass('active');
            this.style.height = 40 + "px";
        }

        var $minichatFooter = parseInt($('.messages-block .chat-footer').height(), 10);
        $(this).closest('.messages-block').find('.messages-list-mobi').css('height', 465 - $minichatFooter + 'px');

        var value = $(this).val();
        var getRegs = value.match(/^.*(\r\n|\n|$)/gim);
        var countRow = 0;
        for (var i = 0; i < getRegs.length; i++) {
            value = getRegs[i].replace(/\r|\n/g, "");
            countRow += value.length ? Math.ceil(value.length / 50) : 1;
        }
        var $chatFooter = parseInt($('.chat-info .chat-footer').height(), 10);
        setTimeout(function () {
            $(this).closest('.chat-info').find('.messages-list').css('height', 600 - $chatFooter + 'px');
        }, 200)

        if (event.keyCode == 13) {
            var content = this.value;
            var caret = getCaret(this);

            if (event.shiftKey) {
                event.stopPropagation();
            } else {
                sendMessage(this);
                $(this).attr('style', false);
            }
        }

    })

    function getCaret(el) {
        if (el.selectionStart) {
            return el.selectionStart;
        } else if (document.selection) {
            el.focus();
            var r = document.selection.createRange();
            if (r == null) {
                return 0;
            }
            var re = el.createTextRange(), rc = re.duplicate();
            re.moveToBookmark(r.getBookmark());
            rc.setEndPoint('EndToStart', re);
            return rc.text.length;
        }
        return 0;
    }

    $('html').on('click', '.send-message-chat', function () {
        var messageInput = $(this).parents('.chat-footer').find('.messageInput');
        sendMessage(messageInput);
    });

    $('html').on('click', '.template-message', function () {
        $('.template-message').each(function () {
            $(this).removeClass('active')
        })
        $('.chat-footer textarea').val($(this).attr('data-template'))
        $('.chat-footer textarea').css('height', $('.chat-footer textarea').prop("scrollHeight") + 'px');
        $('.send-message').addClass('active');
        if ($(this).is('.active')) {
            $(this).removeClass('active')
        } else {
            $(this).addClass('active')
        }
    })

    if ($('.messages-list').is('div')) {
        var chatWrap = $('.messages-list');
        chatWrap.scrollTop(chatWrap.prop("scrollHeight"));
    }

    $(document).on('click', '.openRemoveModal', function () {
        $('.removeTemplate').attr('data-id', $(this).attr('data-id'));
        $('.removeTemplateMini').attr('data-id', $(this).attr('data-id'));
    });

    $('html').on('click', '.removeTemplate, .removeTemplateMini', function () {
        $.ajax({
            'url': $(this).attr('data-url'),
            'type': 'get',
            'data': "type=remove&params[id]=" + $(this).attr('data-id'),
            'success': function (data) {
                $('#big-chat .templates-messages').html($(data).find('.big'));
                $('#removeTemplateMessage').modal('hide');

                $('.mini-chat .templates-messages').html($(data).find('.mini'));
                $('#removeTemplateMessage-modal').animate({
                    top: '-100%',
                }, 500);
                reInitMessagesHeight();
            }
        });
    });

    $('html').on('click', '.addNewTemplate, .addNewTemplateMini', function () {

        if ($(this).is('.addNewTemplate')) {
            var content = $('#addTemplateMessage .templateString').val();
        } else {
            var content = $('#addTemplateMessage-modal .templateString').val();
        }

        $.ajax({
            'url': $('.addNewTemplate').attr('data-url'),
            'type': 'get',
            'data': "type=add&params[content]=" + content,
            'success': function (data) {

                if ($(data).find('div').is('.errorMessage')) {
                    renderAlert($(data).find('.errorMessage').html(), 'danger', 2000);
                } else {
                    $('#big-chat .templates-messages').html($(data).find('.big'));
                    $('#addTemplateMessage').modal('hide');

                    $('.mini-chat .templates-messages').html($(data).find('.mini'));
                    $('#addTemplateMessage-modal').animate({
                        top: '-100%',
                    }, 500);
                    reInitMessagesHeight();
                }

                $('.templateString').val('')
            }
        });
    });

    $('.mini-chat-wrap .mini-icon').on('click', function (e) {
        if ($('.mini-chat-wrap').is('.active')) {
            $('.mini-chat .messages-block').html('');
            $('.mini-chat .messages-block').css('left', '320px');
            $('.mini-chat-wrap').removeClass('active');
        } else {
            e.preventDefault()
            openModalChat(true);
        }
    });

    $('html').on('click', '.messages-block .fa-angle-left', function () {
        $('.mini-chat-wrap .body .messages-block').animate({
            left: 320
        }, 500);
    });

    var update = true;
    document.addEventListener('scroll', function (event) {
        if (typeof (event.target.attributes) != "undefined" && event.target.attributes.class.value) {
            if (event.target.attributes.class.value === 'messages-list-mobi') {
                if ($('.messages-list-mobi').scrollTop() < 1) {
                    $('.mini-chat-wrap .preload-wrap').css('display', 'flex');
                    $('.mini-chat-wrap .preload-wrap').css('opacity', 1);
                    var startingAfter = $('.messages-list-mobi > .message:first-child').attr('data-id');
                    var conversationId = $('.messages-list-mobi').attr('data-id');

                    var oldCount = $('.messages-list-mobi > .message').length;

                    if (update) {
                        update = false;
                        $.ajax({
                            url: $('.messages-list-mobi').attr('data-url'),
                            type: "GET",
                            data: 'conversationId=' + conversationId + '&startingAfter=' + startingAfter,
                            success: function (data) {
                                $('.messages-list-mobi').prepend(data);
                                var newCount = $('.messages-list-mobi > .message').length;
                                var summHeight = 0;
                                var count = newCount - oldCount;
                                if (data != '') {
                                    $('.messages-list-mobi .message').each(function () {
                                        if (count > 0) {
                                            summHeight = summHeight + parseInt($(this).height() + 10);
                                        }
                                        count--;
                                    });
                                    $('.messages-list-mobi').scrollTop(summHeight);
                                }
                                update = true;
                                $('.mini-chat-wrap .preload-wrap').hide();
                                $('.mini-chat-wrap .preload-wrap').css('opacity', 0);
                            }
                        });
                    }
                }
            }
        }
    }, true);

    document.addEventListener('scroll', function (event) {
        if (typeof (event.target.attributes) != "undefined" && event.target.attributes.class.value) {
            if (event.target.attributes.class.value === 'messages-list') {
                if ($('.chat-wrapper .messages-list').scrollTop() < 1) {
                    $('.chat-wrapper .preload-wrap').addClass('not-first-update');
                    $('.chat-wrapper .preload-wrap').css('display', 'flex');
                    $('.chat-wrapper .preload-wrap').css('opacity', 1);
                    var startingAfter = $('.messages-list > .message:first-child').attr('data-id');
                    var conversationId = $('.messages-list').attr('data-id');

                    var oldCount = $('.messages-list > .message').length;

                    if (update) {
                        update = false;
                        $.ajax({
                            url: $('.chat-wrapper .messages-list').attr('data-url'),
                            type: "GET",
                            data: 'conversationId=' + conversationId + '&startingAfter=' + startingAfter,
                            success: function (data) {
                                $('.chat-wrapper .messages-list').prepend(data);
                                var newCount = $('.chat-wrapper .messages-list > .message').length;
                                var summHeight = 0;
                                var count = newCount - oldCount;
                                if (data != '') {
                                    $('.chat-wrapper .messages-list .message').each(function () {
                                        if (count > 0) {
                                            summHeight = summHeight + parseInt($(this).height() + 20);
                                        }
                                        count--;
                                    });
                                    $('.chat-wrapper .messages-list').scrollTop(summHeight);
                                }
                                update = true;
                                $('.chat-wrapper .preload-wrap').hide();
                                $('.chat-wrapper .preload-wrap').css('opacity', 0);
                            }
                        });
                    }
                }
            }
        }
    }, true);

    var updateContact = true;
    document.addEventListener('scroll', function (event) {
        if (typeof (event.target.attributes) != "undefined" && event.target.attributes.class.value) {
            if (event.target.attributes.class.value === 'contact-list big') {
                var offset = $('.contact-list.big .all-height').height() - $('.contact-list.big').scrollTop() - $('.contact-list.big').height();
                if (offset < 2) {
                    var lastId = $('.contact-list.big .all-height a:last-child').attr('data-id');
                    if (updateContact) {
                        updateContact = false;
                        $('.grid-preloader').addClass('active');
                        $.ajax({
                            url: $('.contact-list.big').attr('data-url'),
                            type: "GET",
                            data: 'last-id=' + lastId,
                            success: function (data) {
                                $('.grid-preloader').removeClass('active');
                                $('.contact-list.big .all-height').append(data);
                                updateContact = true;
                            }
                        });
                    }
                }
            }
        }
    }, true);

    $('html').on('click', '.user-block-chat .back', function () {
        $('.mini-chat-wrap .body .user-block-chat').animate({
            left: 320
        }, 500);
    });

    $('body').on('click', '*[data-toggle=mini-modal]', function () {
        var modal = $(this).attr('data-target');

        var offen = (parseInt($('.mini-chat').height()) - parseInt($(modal).height())) / 2;
        $(modal).animate({
            top: offen + 'px',
        }, 500);
    });

    $('body').on('click', '#big-chat .contact-list.big a', function (e) {
        var link = this;
        $('#big-chat .preload-wrap.update-conversation').css('display', 'flex');
        $('#big-chat .preload-wrap.update-conversation').animate({
            opacity: 1
        }, 500);
        e.preventDefault();
        $('#big-chat .contact-list.big .user-info').removeClass('active');
        $(this).find('.user-info').addClass('active');
        $.ajax({
            'url': $(this).attr('data-href'),
            'type': 'get',
            'success': function (data) {
                $(link).find('.count').removeClass('active');

                $('#big-chat .preload-wrap.update-conversation').animate({
                    opacity: 0
                }, 500, function () {
                    $(this).css('display', 'none');
                });

                $('#big-chat .chat-info').html(data);
                var chatWrap = $('.messages-list');
                chatWrap.scrollTop(chatWrap.prop("scrollHeight"));
            }
        });
    });

    if ($('.contact-list.big').is('div')) {
        $(window).scroll(function (event) {
            event.preventDefault();
        });
    }

    $('html').on('click', '.button--link-to-chat', function (e) {
        e.preventDefault();
        prepareOpenModalChat($(this).attr('href'));
    });
    
    $(document).on('click','.mobileChat',function(e){
        if ($(window).width() < 768) {
            e.preventDefault()
            if($(this).is('.user-menu__state')){
                $('.mini-icon').trigger('click')
                return;
            }
            prepareOpenModalChat($(this).attr('href'));
        }
    })

    $(document).on('click touch', '.chat-header .conversation-list-button, .user-info', function () {
        $('.dialog-window').toggleClass('active')
    });

    $(document).on('click touch', '.chat-header .view-profile-button, .dialog-message-button', function () {
        $('.sidebar-window').toggleClass('active')
    });

    $(document).on('click touch', 'addTemplateLine.mobi,.templates-messages-button.mobi', function () {
        $('.templates-list').toggle()
        let $bh = parseInt($('.mini-chat .templates-messages').css('height'), 10);
        if ($(this).is('.active')) {
            $(this).removeClass('active')
        } else {
            $(this).addClass('active')
        }
    });

    $(document).on('click touch', '.addTemplateLine.desk, .templates-messages-button.desk', function () {
        $('.templates-list').toggle()
        let $bh = parseInt($('.templates-messages').css('height'), 10);
        $('.messages-list').css('padding-bottom', $bh);
        $('.messages-list').scrollTop(100 + $('.messages-list')[0].scrollHeight);
    });

    $('.users-search .searh_button,.users-search-button').on('click touch', function () {
        $(this).parent().find('input').toggleClass('active')
        $(this).parent().find('input').focus()
    })

    $('html').on('click', '.open-conversation', function () {
        openConversation($(this).attr('data-id'));
        $(this).parents('.user-info').find('.count').removeClass('active');
    });

    $('html').on('click', '.addReport', function () {
        $('input[name=conversation]').val($(this).attr('data-conversation'));
    });

    $('html').on('click', '#addReport button.addReportButton', function () {

        var data = {
            conversation: $('#addReport input[name=conversation]').val(),
            report_type: $('#addReport #user-report-type').val(),
            report_message: $('#addReport textarea[name=report_message]').val(),
        };

        $.ajax({
            'url': $(this).attr('data-action'),
            'type': 'get',
            'data': data,
            'success': function (data) {
                if ($(data).find('.alert-block').hasClass('success')) {
                    $('#addReport').modal('hide');
                }
                $('#addReport .alert-block').html(data);
            }
        });
    });

    $('html').on('click', '.messages-block .fa-angle-down', function () {
        $(this).closest('.messages-block').toggleClass('subbed')
    });

    $('html').on('click', '#addReport-modal button.addReportButton', function () {

        var data = {
            conversation: $('#addReport-modal input[name=conversation]').val(),
            report_type: $('#addReport-modal #user-report-type-mini').val(),
            report_message: $('#addReport-modal textarea[name=report_message]').val(),
        };

        $.ajax({
            'url': $(this).attr('data-action'),
            'type': 'get',
            'data': data,
            'success': function (data) {
                $('#addReport-modal .alert-block').html(data);
            }
        });
    });

    $('body').on('click', '.mini-modal .close, .mini-modal .close-link', function () {
        $('#addTemplateMessage-modal .errorMessage').html('');
        var typeClose = $(this).attr('data-dismiss');
        if (typeClose == 'modal') {
            $(this).parents('.mini-modal').animate({
                top: '-100%',
            }, 500);
        }

    });

});
