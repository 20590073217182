function initializeVideoPlayers() {
  $(document).on("mouseenter", ".js-video-container", function () {
    const container = $(this);
    const videoPlayer = container.find(".js-video-player");

    if (videoPlayer.length) {
      videoPlayer[0].muted = true; 
      videoPlayer[0].play().then(function () {
        container.addClass("preloaded");
      }).catch(function (error) {
        console.log("Failed to play video:", error);
      });
    }
  });

  $(document).on("mouseleave", ".js-video-container", function () {
    const container = $(this);
    const videoPlayer = container.find(".js-video-player");

    if (videoPlayer.length) {
      videoPlayer[0].pause();
    }
  });
}

export { initializeVideoPlayers };
