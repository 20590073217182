(function ($) {
  jQuery.fn.tabsIndicator = function (options) {
    return this.each(function () {
      let tabsContainer = $(this);
      let tabsContainerItems = tabsContainer.find('li:not(.disabled), label');
      let parentContainer = tabsContainerItems.parent();
      let indicator = parentContainer.find('.indicator');

      let activeTab = tabsContainerItems.filter('.active');

      function updateIndicatorPosition(activeTab) {
        if (activeTab && activeTab.length && tabsContainer && tabsContainer.length) {
          let currentTab = activeTab.first();
          let activeTabOffset = currentTab.offset().left - tabsContainer.offset().left;
          let activeTabWidth = currentTab.outerWidth();

          indicator.css({
            width: activeTabWidth,
            transform: `translateX(${activeTabOffset}px)`,
          });
        }
      }

      function initDefaultIndicator() {
        if (!indicator.length) {
          indicator = $('<div>', { class: 'indicator' });
          indicator.appendTo(parentContainer);
        }
        indicator.css({
          position: 'absolute',
          top: '0',
          height: '100%',
        });
        updateIndicatorPosition(activeTab);
      }

      function activateTab(activeTab) {
        updateIndicatorPosition(activeTab);
      }


      function determineTabPosition(currentItem) {
        const elementLeft = currentItem.offset().left;
        const pos = elementLeft - tabsContainer.offset().left;

        return pos === 0 ? 'left' : 'right';
      }

      function updateTabContainerClass(tabPosition) {

        if(parentContainer.hasClass('tabs-indicator-notification')) {
          parentContainer
          .addClass(tabPosition === 'left' ? 'indicator-left' : 'indicator-right')
          .removeClass(tabPosition === 'left' ? 'indicator-right' : 'indicator-left');
        }

      }

      tabsContainerItems.off('click').on('click', function () {

        let position = determineTabPosition($(this));

        updateTabContainerClass(position);

        //activeTab.removeClass('active');

        $(this).css({
          'background-color': 'transparent',
          'border-radius': 0,
        });

        indicator.css({ transition: 'transform 0.7s' });
        activateTab($(this));

      });

      $(window).on('resize load', function () {
        initDefaultIndicator();
        if (options.hideMobile === true) {
          if ($(window).width() < 767) {
            indicator.remove();
          }
        }
      });

      initDefaultIndicator();
    });
  };
})(jQuery);
