import $ from 'jquery';
function sortFilter() {
  $(document).on('click', '.js-sort-dropdown-container', function(e) {
    e.preventDefault();
    let container = $(this);
    let isActive = container.hasClass('active');

    if (isActive) {
      container.removeClass('active');
    } else {
      $(".js-sort-dropdown-container.active").removeClass("active");
      container.addClass('active');
    }
    e.stopPropagation(); 
  });

  $(document).on('click', '.js-sort-dropdown-item', function(e) {
    let container = $(this).closest('.js-sort-dropdown-container');
    container.removeClass('active');
    e.stopPropagation();
  });

  $(document).on('click', function(e) {
    if (!$(e.target).closest('.js-sort-dropdown-container').length) {
      $(".js-sort-dropdown-container").removeClass("active");
    }
  });

}
  
function mobFilters(){
  let mobFiltersContainer = $('.js-mob-filters-container');
  let mobFilterBtnOpen = mobFiltersContainer.find($('.js-open-mob-filters'));
  let mobFilterBtnClose = mobFiltersContainer.find($('.js-close-mob-filters'));
  let mobFiltersModal = mobFiltersContainer.find($('.js-mob-filters-modal'));
  let setDefaultValBtn = mobFiltersContainer.find($('.js-filters-set-default-val')); 
  let mobFilterBtnClear = mobFiltersContainer.find($('.js-clear-mob-filters'));




  $(mobFilterBtnOpen).on('click', function(e) {
    $(mobFiltersModal).addClass('active');
    $('body').addClass('noscroll')
  });

  $(mobFilterBtnClose).on('click', function(e) {
    $(mobFiltersModal).removeClass('active');
    $('body').removeClass('noscroll')
  });

  $(mobFilterBtnClear).on('click', function(){    
    $('.js-search-wrap:visible .js-filters-select').each(function(){
      let setFirstValue = $(this).find('option:nth-child(1)').val();
      if(setFirstValue === '0'){
        $(this).val(0).customSelect2();
      } else {
        $(this).val('').customSelect2();
      }
    });

    $('.js-filter-input').find('input.form-control').val('')

    $('.js-search-wrap:visible .js-filters-select-per-page, .js-search-wrap:visible .js-filters-select-region-links').each(function(){
      let thisFirstChild = $(this).find('option:nth-child(1)');
      let setFirstValue = $(this).find('option:nth-child(1)').val();
      $(this).val(setFirstValue).customSelect2();
      $(this).eq(setFirstValue).trigger('change');

      if($(this).hasClass('js-filters-select-region-links')){
        let url = $(this).find(thisFirstChild).data('link');
        if (typeof url !== "undefined") {
            window.location.href = url;
        }
      }
    });

    $('.js-search-wrap:visible input.inline-checkbox').each(function(){
        $(this).prop('checked', '');
    });
    $('.js-search-wrap:visible input.inline-checkbox').eq(0).prop('checked', 'checked');
    
    $('.js-search-wrap:visible .js-filters-select, .js-search-wrap:visible select.custom-select').eq(0).trigger('change');

    if($('.filters-custom__item').find('.autokeyup').length){
      $('.filters-custom__item').find('.autokeyup').keyup()
    }
  });
  
}

function sideFilters(){
  $('.background-page').on('click', function(e) {
    const sideFiltersBtn = $('.js-side-filters-btn');
    const sideFiltersContainer = $('.js-side-filters-container');

    const activeFilterTitle = $('.js-filters-acc-parent-active').find('.js-filters-acc-title');

    if(activeFilterTitle.hasClass('active')){
      
    } else {
      setTimeout(function() { 
        $('.js-filters-acc-parent').find('.js-filters-acc-title').removeClass('active')
        $(".js-filters-acc-parent").find(".js-filters-acc-body").slideUp("300");
    
        $('.js-filters-acc-parent-active').find('.js-filters-acc-title').addClass('active')
        $(".js-filters-acc-parent-active").find(".js-filters-acc-body").slideDown("300");
      }, 300);
    }

    sideFiltersBtn.removeClass('active');
    sideFiltersContainer.removeClass('active');
    //$('.background-page').removeClass('active');
    $('.modal-background').removeClass('active')
    $('.background-wrapper--user-menu').removeClass('active');
    $('body').removeClass('noscroll')
  });

  $('.js-side-filters-close-btn').on('click', function(e) {
    e.stopPropagation(); 
    $('.background-sidebar').removeClass('active');

    const sideFiltersBtn = $('.js-side-filters-btn');
    const sideFiltersContainer = $('.js-side-filters-container');

    const activeFilterTitle = $('.js-filters-acc-parent-active').find('.js-filters-acc-title');

    if(activeFilterTitle.hasClass('active')){
      
    } else {
      setTimeout(function() { 
        $('.js-filters-acc-parent').find('.js-filters-acc-title').removeClass('active')
        $(".js-filters-acc-parent").find(".js-filters-acc-body").slideUp("300");
    
        $('.js-filters-acc-parent-active').find('.js-filters-acc-title').addClass('active')
        $(".js-filters-acc-parent-active").find(".js-filters-acc-body").slideDown("300");
      }, 300);
    }
  
    sideFiltersBtn.removeClass('active');
    sideFiltersContainer.removeClass('active');
    //$('.background-page').removeClass('active');
    $('.modal-background').removeClass('active')
    $('.background-wrapper--user-menu').removeClass('active');
    $('body').removeClass('noscroll')
  });
  
  $('.js-side-filters-btn').on('click', function(e) {
    e.stopPropagation(); 
  
    const sideFiltersBtn = $(this);
    const sideFiltersContainer = $('.js-side-filters-container');
  
    sideFiltersBtn.addClass('active');
    sideFiltersContainer.addClass('active');
    //$('.background-page').addClass('active');
    $('.modal-background').addClass('active')
    $('.background-wrapper--user-menu').addClass('active');
    $('body').addClass('noscroll')
  });
}
function sideFiltersAcc(){
$(document).on('click', '.js-filters-acc-container .js-filters-acc-title', function () {
  let mobCatContainer = $(this).parents(".js-filters-acc-container");
  let mobCatParent = $(this).closest(".js-filters-acc-parent");
  let mobCatList = mobCatParent.find(".js-filters-acc-body");

  if ($(this).hasClass("active")) {
      let otherTitles = mobCatContainer.find(".js-filters-acc-title:not(.active)");
      let otherList = otherTitles.closest(".js-filters-acc-parent").find(".js-filters-acc-body");
      otherTitles.first().addClass("active");
      otherList.first().slideDown("300");
  

      $(this).removeClass("active");
      mobCatList.slideUp("300");
  } else {
      let otherTitles = mobCatContainer.find(".js-filters-acc-title.active");
      let otherList = otherTitles.closest(".js-filters-acc-parent").find(".js-filters-acc-body");
      otherTitles.first().removeClass("active");
      otherList.first().slideUp("300");

      $(this).addClass("active");
      mobCatList.slideDown("300");

  }
});
};

export { sortFilter, mobFilters, sideFilters, sideFiltersAcc };
