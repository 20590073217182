const localization = {
    'en': {
        'incorrectNumberOfFiles': 'Incorrect number of files selected.',
        'errorUploading': 'Error uploading file.',
        'errorDeletingFromServer': 'Error deleting file from server.',
        'fileTooBig': 'File is too big. Max size is {maxSize} MB.',
        'invalidFileType': 'Invalid file type. Allowed types are: {allowedTypes}.',
        'duplicateFile': 'This file is already added',
        'invalidFile': 'Invalid file.',
        'successUpload': 'Document uploaded',
    },
    'ar': {
        'incorrectNumberOfFiles': 'عدد غير صحيح من الملفات المحددة.',
        'errorUploading': 'خطأ في تحميل الملف.',
        'errorDeletingFromServer': 'خطأ في حذف الملف من الخادم.',
        'fileTooBig': 'الملف كبير جدًا. الحد الأقصى للحجم هو {maxSize} ميغابايت.',
        'invalidFileType': 'نوع الملف غير صالح. الأنواع المسموح بها هي: {allowedTypes}.',
        'duplicateFile': 'هذا الملف مضاف بالفعل',
        'invalidFile': 'ملف غير صالح.',
        'successUpload': 'تم تحميل المستند',
    },
    'zh': {
        'incorrectNumberOfFiles': '选择的文件数量不正确。',
        'errorUploading': '上传文件时出错。',
        'errorDeletingFromServer': '从服务器删除文件时出错。',
        'fileTooBig': '文件太大。最大大小为 {maxSize} MB。',
        'invalidFileType': '文件类型无效。允许的类型为：{allowedTypes}。',
        'duplicateFile': '此文件已添加',
        'invalidFile': '无效的文件。',
        'successUpload': '文档已上传',
    },
    'da': {
        'incorrectNumberOfFiles': 'Forkert antal filer valgt.',
        'errorUploading': 'Fejl ved upload af fil.',
        'errorDeletingFromServer': 'Fejl ved sletning af fil fra serveren.',
        'fileTooBig': 'Filen er for stor. Maksimal størrelse er {maxSize} MB.',
        'invalidFileType': 'Ugyldig filtype. Tilladte typer er: {allowedTypes}.',
        'duplicateFile': 'Denne fil er allerede tilføjet',
        'invalidFile': 'Ugyldig fil.',
        'successUpload': 'Dokument uploadet',
    },
    'de': {
        'incorrectNumberOfFiles': 'Falsche Anzahl von ausgewählten Dateien.',
        'errorUploading': 'Fehler beim Hochladen der Datei.',
        'errorDeletingFromServer': 'Fehler beim Löschen der Datei vom Server.',
        'fileTooBig': 'Datei ist zu groß. Maximale Größe beträgt {maxSize} MB.',
        'invalidFileType': 'Ungültiger Dateityp. Erlaubte Typen sind: {allowedTypes}.',
        'duplicateFile': 'Diese Datei ist bereits hinzugefügt',
        'invalidFile': 'Ungültige Datei.',
        'successUpload': 'Dokument hochgeladen',
    },
    'es': {
        'incorrectNumberOfFiles': 'Número incorrecto de archivos seleccionados.',
        'errorUploading': 'Error al cargar el archivo.',
        'errorDeletingFromServer': 'Error al eliminar el archivo del servidor.',
        'fileTooBig': 'El archivo es demasiado grande. El tamaño máximo es {maxSize} MB.',
        'invalidFileType': 'Tipo de archivo no válido. Los tipos permitidos son: {allowedTypes}.',
        'duplicateFile': 'Este archivo ya está agregado',
        'invalidFile': 'Archivo no válido.',
        'successUpload': 'Documento cargado',
    },
    'el': {
        'incorrectNumberOfFiles': 'Número incorrecto de archivos seleccionados.',
        'errorUploading': 'Error al subir el archivo.',
        'errorDeletingFromServer': 'Error al eliminar el archivo del servidor.',
        'fileTooBig': 'El archivo es demasiado grande. El tamaño máximo es {maxSize} MB.',
        'invalidFileType': 'Tipo de archivo no válido. Tipos permitidos: {allowedTypes}.',
        'duplicateFile': 'Este archivo ya está añadido',
        'invalidFile': 'Archivo no válido.',
        'successUpload': 'Documento subido',
    },
    'es': {
        'incorrectNumberOfFiles': 'Número incorrecto de archivos seleccionados.',
        'errorUploading': 'Error al cargar el archivo.',
        'errorDeletingFromServer': 'Error al eliminar el archivo del servidor.',
        'fileTooBig': 'El archivo es demasiado grande. El tamaño máximo es {maxSize} MB.',
        'invalidFileType': 'Tipo de archivo no válido. Tipos permitidos: {allowedTypes}.',
        'duplicateFile': 'Este archivo ya está añadido',
        'invalidFile': 'Archivo no válido.',
        'successUpload': 'Documento cargado',
    },
    'fi': {
        'incorrectNumberOfFiles': 'Väärä määrä valittuja tiedostoja.',
        'errorUploading': 'Virhe tiedoston lataamisessa.',
        'errorDeletingFromServer': 'Virhe tiedoston poistamisessa palvelimelta.',
        'fileTooBig': 'Tiedosto on liian suuri. Maksimikoko on {maxSize} MB.',
        'invalidFileType': 'Virheellinen tiedostotyyppi. Sallitut tyypit ovat: {allowedTypes}.',
        'duplicateFile': 'Tämä tiedosto on jo lisätty',
        'invalidFile': 'Virheellinen tiedosto.',
        'successUpload': 'Dokumentti ladattu',
    },
    'fr': {
        'incorrectNumberOfFiles': 'Nombre incorrect de fichiers sélectionnés.',
        'errorUploading': 'Erreur lors du téléchargement du fichier.',
        'errorDeletingFromServer': 'Erreur lors de la suppression du fichier du serveur.',
        'fileTooBig': 'Le fichier est trop gros. La taille maximale est de {maxSize} Mo.',
        'invalidFileType': 'Type de fichier invalide. Les types autorisés sont : {allowedTypes}.',
        'duplicateFile': 'Ce fichier est déjà ajouté',
        'invalidFile': 'Fichier invalide.',
        'successUpload': 'Document téléchargé',
    },
    'it': {
        'incorrectNumberOfFiles': 'Numero di file selezionati non corretto.',
        'errorUploading': 'Errore durante il caricamento del file.',
        'errorDeletingFromServer': 'Errore durante leliminazione del file dal server.',
        'fileTooBig': 'Il file è troppo grande. Dimensione massima consentita: {maxSize} MB.',
        'invalidFileType': 'Tipo di file non valido. Tipi consentiti: {allowedTypes}.',
        'duplicateFile': 'Questo file è già stato aggiunto',
        'invalidFile': 'File non valido.',
        'successUpload': 'Documento caricato',
    },
    'ja': {
        'incorrectNumberOfFiles': '選択されたファイルの数が正しくありません。',
        'errorUploading': 'ファイルのアップロード中にエラーが発生しました。',
        'errorDeletingFromServer': 'サーバーからファイルを削除する際にエラーが発生しました。',
        'fileTooBig': 'ファイルが大きすぎます。最大サイズは {maxSize} MB です。',
        'invalidFileType': '無効なファイルタイプです。許可されているタイプは次のとおりです: {allowedTypes}。',
        'duplicateFile': 'このファイルはすでに追加されています',
        'invalidFile': '無効なファイルです。',
        'successUpload': 'ドキュメントがアップロードされました',
    },
    'ko': {
        'incorrectNumberOfFiles': '선택한 파일의 수가 올바르지 않습니다.',
        'errorUploading': '파일 업로드 중 오류가 발생했습니다.',
        'errorDeletingFromServer': '서버에서 파일을 삭제하는 중 오류가 발생했습니다.',
        'fileTooBig': '파일이 너무 큽니다. 최대 크기는 {maxSize} MB입니다.',
        'invalidFileType': '유효하지 않은 파일 유형입니다. 허용된 유형은 다음과 같습니다: {allowedTypes}.',
        'duplicateFile': '이 파일은 이미 추가되었습니다',
        'invalidFile': '유효하지 않은 파일입니다.',
        'successUpload': '문서가 업로드되었습니다',
    },
    'nl': {
        'incorrectNumberOfFiles': 'Onjuist aantal geselecteerde bestanden.',
        'errorUploading': 'Fout bij het uploaden van het bestand.',
        'errorDeletingFromServer': 'Fout bij het verwijderen van het bestand van de server.',
        'fileTooBig': 'Bestand is te groot. Max grootte is {maxSize} MB.',
        'invalidFileType': 'Ongeldig bestandstype. Toegestane typen zijn: {allowedTypes}.',
        'duplicateFile': 'Dit bestand is al toegevoegd',
        'invalidFile': 'Ongeldig bestand.',
        'successUpload': 'Document geüpload',
    },
    'no': {
        'incorrectNumberOfFiles': 'Feil antall filer valgt.',
        'errorUploading': 'Feil ved opplasting av fil.',
        'errorDeletingFromServer': 'Feil ved sletting av fil fra serveren.',
        'fileTooBig': 'Filen er for stor. Maksimal størrelse er {maxSize} MB.',
        'invalidFileType': 'Ugyldig filtype. Tillatte typer er: {allowedTypes}.',
        'duplicateFile': 'Denne filen er allerede lagt til',
        'invalidFile': 'Ugyldig fil.',
        'successUpload': 'Dokument lastet opp',
    },
    'pl': {
        'incorrectNumberOfFiles': 'Nieprawidłowa liczba wybranych plików.',
        'errorUploading': 'Błąd podczas przesyłania pliku.',
        'errorDeletingFromServer': 'Błąd podczas usuwania pliku z serwera.',
        'fileTooBig': 'Plik jest za duży. Maksymalny rozmiar to {maxSize} MB.',
        'invalidFileType': 'Nieprawidłowy typ pliku. Dozwolone typy to: {allowedTypes}.',
        'duplicateFile': 'Ten plik został już dodany',
        'invalidFile': 'Nieprawidłowy plik.',
        'successUpload': 'Dokument przesłany',
    },
    'pt': {
        'incorrectNumberOfFiles': 'Número incorreto de arquivos selecionados.',
        'errorUploading': 'Erro ao carregar o arquivo.',
        'errorDeletingFromServer': 'Erro ao excluir o arquivo do servidor.',
        'fileTooBig': 'O arquivo é muito grande. O tamanho máximo é de {maxSize} MB.',
        'invalidFileType': 'Tipo de arquivo inválido. Tipos permitidos são: {allowedTypes}.',
        'duplicateFile': 'Este arquivo já foi adicionado',
        'invalidFile': 'Arquivo inválido.',
        'successUpload': 'Documento carregado',
    },
    'ru': {
        'incorrectNumberOfFiles': 'Неверное количество выбранных файлов.',
        'errorUploading': 'Ошибка загрузки файла.',
        'errorDeletingFromServer': 'Ошибка удаления файла с сервера.',
        'fileTooBig': 'Файл большой. Максимальный размер {maxSize} МБ.',
        'invalidFileType': 'Недопустимый тип файла. Разрешённые типы: {allowedTypes}.',
        'duplicateFile': 'Этот файл уже добавлен',
        'invalidFile': 'Недопустимый файл.',
        'successUpload': 'Документ загружен',
    },
    'sv': {
        'incorrectNumberOfFiles': 'Fel antal valda filer.',
        'errorUploading': 'Fel vid uppladdning av fil.',
        'errorDeletingFromServer': 'Fel vid radering av fil från servern.',
        'fileTooBig': 'Filen är för stor. Maximal storlek är {maxSize} MB.',
        'invalidFileType': 'Ogiltig filtyp. Tillåtna typer är: {allowedTypes}.',
        'duplicateFile': 'Denna fil är redan tillagd',
        'invalidFile': 'Ogiltig fil.',
        'successUpload': 'Dokumentet är uppladdat',
    },
    'tr': {
        'incorrectNumberOfFiles': 'Seçilen dosya sayısı yanlış.',
        'errorUploading': 'Dosya yüklenirken hata oluştu.',
        'errorDeletingFromServer': 'Sunucudan dosya silinirken hata oluştu.',
        'fileTooBig': 'Dosya çok büyük. Maksimum boyut {maxSize} MB.',
        'invalidFileType': 'Geçersiz dosya türü. İzin verilen türler: {allowedTypes}.',
        'duplicateFile': 'Bu dosya zaten eklenmiş',
        'invalidFile': 'Geçersiz dosya.',
        'successUpload': 'Belge yüklendi',
    },
};

export default localization;
