import "../../scss/common/region-info-block/region-info-modal.scss";

function filterCountrySearchFunc() {
  window.filterCountrySearch = function (e) {
    let filter = $(e.target).val().toUpperCase();
    $(".js-filter-result li").each(function () {
      let txtValue = $(this).text().toUpperCase();
      if (txtValue.indexOf(filter) > -1) {
        $(this).show();
        $(this).removeClass("noactive");
        $(this).addClass("active");
        $(".js-filter-result").removeClass("noscroll");
      } else {
        $(this).hide();
        $(this).addClass("noactive");
        $(this).removeClass("active");
        $(".js-filter-result").addClass("noscroll");
      }
    });

    let fullListSum = $(".js-filter-result li").length;
    let activeListSum = $(".js-filter-result li.active").length;
    let noactiveListSum = $(".js-filter-result li.noactive").length;
    if (fullListSum !== 0 && fullListSum == noactiveListSum) {
      $(".js-region-search-empty").removeClass("noactive");
      $('.js-modal-scroll-shadow--bottom').removeClass('active');
      $(".js-modal-scroll-container").removeClass("active-padding");
    } else {
      $(".js-region-search-empty").addClass("noactive");
      $('.js-modal-scroll-shadow--bottom').addClass('active');
      $(".js-modal-scroll-container").addClass("active-padding");
    }

    if (activeListSum <= 7) {
      $(".js-filter-result li.active").addClass("visible");
      $('.js-modal-scroll-shadow--bottom').removeClass('active');
      $(".js-modal-scroll-container").removeClass("active-padding");
    } else {
      $(".js-filter-result li.active").removeClass("visible");
      $('.js-modal-scroll-shadow--bottom').addClass('active');
      $(".js-modal-scroll-container").addClass("active-padding");
    }

    if (filter !== "") {
      $(".js-search-input-container").addClass("active");
    } else {
      $(".js-search-input-container").removeClass("active");
    }
  };
}

function regionModalDecor() {
  if ($("#ajax-modal").find(".js-filter-result")) {
    $(".js-filter-result").scroll(function () {
      let topShadow = $(this).parents('.js-modal-scroll-container').find('.js-modal-scroll-shadow--top');
      let topShadowHideHeight = topShadow.attr('data-scroll-height');
      let bottomShadow = $(this).parents('.js-modal-scroll-container').find('.js-modal-scroll-shadow--bottom');
      let maxScrollY = $(this).prop('scrollHeight') - $(this).height();
      // console.log(maxScrollY, 'max');
      // console.log($(this).scrollTop());
      if ($(this).scrollTop() > topShadowHideHeight) {
          topShadow.addClass("active");
      } else {
          topShadow.removeClass("active");
      }
      if ($(this).scrollTop() >= (maxScrollY - 5)) {
          bottomShadow.removeClass("active");
      } else {
          bottomShadow.addClass("active");
      }

    });

    if(!$(".js-filter-result li").length){
      $('.js-modal-scroll-shadow--bottom').removeClass('active');
      $(".js-modal-scroll-container").removeClass("active-padding");
    }
    if($(".js-filter-result li").length <= 7){
      $('.js-modal-scroll-shadow--bottom').removeClass('active');
      $(".js-modal-scroll-container").removeClass("active-padding");
      $('.js-modal-search-wrap').hide();
    }
  }
}

export { regionModalDecor, filterCountrySearchFunc };
