import $, { get } from 'jquery';
import { sortFilter, mobFilters, sideFilters, sideFiltersAcc } from '../parts-custom/sort-filters.js';
import { regionModalDecor, filterCountrySearchFunc } from '../parts/region-modal.js';
import { socialSharerLoad } from "../parts-custom/sharer-custom.js";
import { initializeVideoPlayers } from "../parts-custom/product-video-init.js";
import io from 'socket.io-client';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

require('slick-carousel-latest/slick/slick');
import 'slick-carousel-latest/slick/slick.scss';

const adaptive = $(window).width() < 768;

const selectors = [
    '.mini-cart .down', 
    '.mini-cart .up', 
    '.mini-cart .delete-icon', 
    '#cardModal .down', 
    '#cardModal .up', 
    '#cardModal .delete-icon', 
    '.mini-cart-moby .down', 
    '.mini-cart-moby .up', 
    '.mini-cart-moby .delete-icon'
];

$(window).on('popstate', function(event) {
    location.reload();
});

function toggleSlide(el, maxHeight) {
    let height = 0
    if (typeof maxHeight !== 'undefined') {
        height = maxHeight
    } else {
        height = el.prop('scrollHeight')
    }
    el.css('max-height', height)
}

export function throttle(fn, wait) {
    let inThrottle;
    return function () {
        const context = this, args = arguments;
        if (!inThrottle) {
            fn.apply(context, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, wait);
        }
    }
}

export function initInputPlaceholderPadding(){
    $('.form-group.with-placeholder').each(function(){
        let placeholder = $(this).find('.input-placeholder')
        let input = $(this).find('.form-control')
        input.css({
            'padding-left':placeholder.outerWidth()+'px'
        })
    })
}

export function countFilter(wrap, isForm) { 
    let fakeSelect = $(wrap).find('.fake-select .value') 
    let expireDate = $(wrap).find('.date-picker__input').val() 
    let inputSearch = $(wrap).find('.search input.form-control').val() 

    let countValue = 0;

    $(fakeSelect).each(function (index) {
        if(isForm){
            if($(fakeSelect[index]).hasClass('chosen')){
                ++countValue
            }
        } else {
            if($(fakeSelect[index]).hasClass('chosen') && $(fakeSelect[index]).is(":visible")){
                ++countValue
            }
        }
    });

    if(inputSearch && inputSearch.length){
        ++countValue
    }

    if( $(wrap).find('.date-picker__input').length > 1){
        $(wrap).find('.date-picker__input').each(function (index) {
            if($(this).val() && $(this).val().length){
                ++countValue
            }
        });
    } else {
        if(expireDate && expireDate.length){
            ++countValue
        }
    }

    if(isForm){
        $(wrap).find('.filter-count-form').html(countValue);
    
        if (countValue){
            $(wrap).find('.filter-count-form').addClass('value')
        } else {
            $(wrap).find('.filter-count-form').removeClass('value')
        }
    } else {
        $('.filter-count').html(countValue);
    
    if (countValue>0){
        $('.filter-count').addClass('value')
    } else {
        $('.filter-count').removeClass('value')
    }
    }
}

export function initInputRestrinction(){
    // latin only
    $('.form-group.latin input').each(function () {
        restrictInput($(this), /^[A-Za-z]+$/);
    });

    // any single word lettes
    $('.form-group.any-word input').each(function () {
        restrictInput($(this), /^[A-Za-zА-Яа-я]+$/);
    });

    // latin extended with NL,NO,SV,DE,DA,FR lang supports
    $('.form-group.latin-full input').each(function () {
        restrictInput($(this), /^[a-zA-Z\u00C0-\u024F]+$/);
    });

    // phone numbers with + as a first symbol and spaces between
    $('.form-group.phone input').each(function () {
        restrictInput($(this), /^[\d\s+]+$/);
    });

    // email validation
    $('.form-group.email input').each(function () {
        restrictInput($(this), /^[a-zA-Z0-9.@\-_]+$/);
    });
    
    // URL validation
    $('.form-group.url input').each(function () {
        restrictInput($(this), /^[A-Za-z0-9_\-./]+$/);
    });

    // number validation
    $('.form-group.number input').each(function () {
        restrictInput($(this), /^[\d\s+]+$/);
    });

    // combined validation basically extended latin with symbols and spaces
    $('.form-group.combined input').each(function () {
        restrictInput($(this), /^[^\u0400-\u04FF]*$/);
    });

    // combined validation basically extended latin with symbols and spaces
    $('.form-group.username input').each(function () {
        restrictInput($(this), /^[A-Za-z0-9_-]+$/);
    });
}



// Input validation handlers
function restrictInput($input, regex) {
    $input.on('input keyup', function (event) {
        event.preventDefault();
        const inputValue = $input.val();
        if (!regex.test(inputValue)) {
            $input.val(inputValue.slice(0, -1));
        }
    });
}

var socket = null;


window.number_format = function (number, decimals = 0, dec_point = '.', thousands_point = ' ') {

    if (number == null || !isFinite(number)) {
        throw new TypeError("number is not valid");
    }

    if (!decimals) {
        var len = number.toString().split('.').length;
        decimals = len > 1 ? len : 0;
    }

    if (!dec_point) {
        dec_point = '.';
    }

    if (!thousands_point) {
        thousands_point = ',';
    }

    number = parseFloat(number).toFixed(decimals);

    number = number.replace(".", dec_point);

    var splitNum = number.split(dec_point);
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
    number = splitNum.join(dec_point);

    return number;
}

window.getClearValue = function (value) {
    return value.replace(/\s+/g, '');
}

$(document).ready(function () {


    sortFilter();
    mobFilters();
    sideFilters();
    sideFiltersAcc();

    filterCountrySearchFunc();
    listScrollShadow();
    dashboardSubineOwerflow();
    showProductDescription();

    socialSharerLoad();

    informerCopyInit();
    copyAllTextInit();

    btnPreloaderOnClick();
    btnAppendedPreloader();

    handleMobileSearchForm();

    checkMiniCartBtn();

    initializeVideoPlayers();

    updatePagination();

    updateMobileCartText();
    initInputRestrinction()

});

$(document).on('pjax:complete', function (event, xhr, status, options) {
    sortFilter();
    mobFilters();

    filterCountrySearchFunc();
    listScrollShadow();
    dashboardSubineOwerflow();
    //showProductDescription();

    socialSharerLoad();

    informerCopyInit();
    copyAllTextInit();

    btnPreloaderOnClick();
    btnAppendedPreloader();

    checkMiniCartBtn();

    initializeVideoPlayers();

    updatePagination();

    updateMobileCartText();
    initInputRestrinction()
});

export function btnAppendedPreloader() {
    addButtonAppendedListener('.js-appended-preloader-green');
}

export function addButtonAppendedListener(selector) {
    const buttons = document.querySelectorAll(selector);
    buttons.forEach(function (button) {
        button.addEventListener('click', function () {
            button.classList.add('preload');

            setTimeout(function () {
                button.classList.remove('preload');
            }, 1000);
        });
    });
}

export function btnPreloaderOnClick() {
    // list of btn preloaders below
    addButtonClickListener('.js-btn-preloader-gray-onClick', 'btn-preloader--gray');
    addButtonClickListener('.js-btn-preloader-red-onClick', 'btn-preloader--red');
    addButtonClickListener('.js-btn-preloader-green-onClick', 'btn-preloader--green');
    addButtonClickListener('.js-btn-preloader-green-onAccepted', 'btn-preloader--green');
    addButtonClickListener('.js-btn-preloader-yellow-onClick', 'btn-preloader--yellow');
    addButtonClickListener('.js-btn-preloader-red-onClick', 'btn-preloader--red');
}

export function addButtonClickListener(selector, preloaderClass) {
    const buttons = document.querySelectorAll(selector);
    buttons.forEach(function (button) {
        button.addEventListener('click', function () {
            if (!this.classList.contains('not-changed')) {
                const oldPreloaders = document.querySelectorAll('.btn-preloader');
                oldPreloaders.forEach(function (oldPreloader) {
                    oldPreloader.remove();
                });

                const preloader = document.createElement('div');
                preloader.classList.add('btn-preloader');
                if (preloaderClass) {
                    preloader.classList.add(preloaderClass);
                }
                button.appendChild(preloader);

                setTimeout(function () {
                    preloader.remove();
                }, 6000);
            }
        });
    });
}

export function regulateSingleDropdown() {
    // $('.custom-select-wrap').each(function(){
    //     let wrap = $(this)
    //     if(wrap.find('.options-list li').length<2){
    //         wrap.addClass('single-list')
    //         if(wrap.is('.autoselect')){
    //             wrap.find('.options-list li:first-child').trigger('click')
    //         }
    //     }else{
    //         wrap.removeClass('single-list')
    //     }
    // })
}

export function listScrollShadow() {
    if ($(".js-scroll-container-wrap").find(".js-scroll-container")) {
        $(".js-scroll-container").scroll(function () {
            let topShadow = $(this).parents('.js-scroll-container-wrap').find('.js-scroll-shadow--top');
            let topShadowHideHeight = topShadow.attr('data-scroll-height');
            let bottomShadow = $(this).parents('.js-scroll-container-wrap').find('.js-scroll-shadow--bottom');
            let bottomShadowHideHeight = parseInt(bottomShadow.attr('data-scroll-height')) || 0;
            let maxScrollY = $(this).prop('scrollHeight') - $(this).height();
            let maxScrollYBtm = maxScrollY - bottomShadowHideHeight;

            if ($(this).scrollTop() > topShadowHideHeight) {
                topShadow.addClass("active");
            } else {
                topShadow.removeClass("active");
            }
            if ($(this).scrollTop() >= maxScrollYBtm) {
                bottomShadow.removeClass("active");
            } else {
                bottomShadow.addClass("active");
            }
        });
    }
}

function dashboardSubineOwerflow() {
    if ($('.js-progress-wrap').length && $('.js-current-level').length) {
        let progressWrap = $('.js-progress-wrap');
        let levelWrapOffset = progressWrap.find('.js-level-wrap').offset();

        let levelOffset = progressWrap.find('.js-current-level').offset();
        let subline = progressWrap.find('.js-dashboard-subline').width();

        let levelPos = levelOffset.left - levelWrapOffset.left;

        if (adaptive) {
            progressWrap.find('.js-dashboard-subline-overflow').width((subline - levelPos) - 25);
        } else {
            progressWrap.find('.js-dashboard-subline-overflow').width((subline - levelPos) - 85);
        }
    }
}

function modalAddClass(className) {
    $('.modal-scrollable').each(function () {
        if (!$(this).hasClass(className)) {
            $(this).addClass(className)
        }
    })
}

function cartScrollShadow() {

    if ($(window).width() > 768) {
        if ($('.js-popup-cart-item').length <= 2) {
            $('.js-cart-scroll-decor--top').hide();
            $('.js-cart-scroll-decor--bottom').hide();
            $('.js-cart-scroll-block').removeClass('active-scroll-height');
        } else {
            $('.js-cart-scroll-decor--top').show();
            $('.js-cart-scroll-decor--bottom').show();
            $('.js-cart-scroll-block').addClass('active-scroll-height');
        }
    }

    let currentPosition = $('.js-cart-scroll-block').scrollTop();
    let checkTopShadow = $('.js-cart-scroll-parent').find('.js-cart-scroll-decor--top');
    let checkTopShadowHideHeight = checkTopShadow.attr('data-scroll-height');
    if (currentPosition > checkTopShadowHideHeight) {
        checkTopShadow.removeClass("noactive");
    } else {
        checkTopShadow.addClass("noactive");
    }

    $(".js-cart-scroll-block").scroll(function () {
        let topShadow = $(this).parents('.js-cart-scroll-parent').find('.js-cart-scroll-decor--top');
        let topShadowHideHeight = topShadow.attr('data-scroll-height');
        let bottomShadow = $(this).parents('.js-cart-scroll-parent').find('.js-cart-scroll-decor--bottom');
        let maxScrollY = $(this).prop('scrollHeight') - $(this).height();

        if ($(this).scrollTop() > topShadowHideHeight) {
            topShadow.removeClass("noactive");
        } else {
            topShadow.addClass("noactive");
        }
        if (($(this).scrollTop() + 5) >= maxScrollY) {
            bottomShadow.addClass("noactive");
        } else {
            bottomShadow.removeClass("noactive");
        }
    });
}

window.updatePagination = function () {
    if ($('.pagination-wrap').length) {
        $('.pagination-wrap').each(function () {
            let paginationWrap = $(this);
            let pagination = paginationWrap.find('.pagination');

            if (pagination.length) {
                let pageList = [];
                pagination.find('.item').each(function () {
                    let pageNumber = parseInt($(this).children().html());
                    if (!pageList.includes(pageNumber)) {
                        pageList.push(pageNumber);
                    }
                });

                pagination.find('.first-item, .last-item').show();

                pagination.find('.item').show();

                let firstItem = pageList[0];
                let lastItem = pageList[pageList.length - 1];

                pagination.find('.item').each(function () {
                    let pageNumber = parseInt($(this).children().html());
                    if ((pageNumber === firstItem && !$(this).hasClass('first-item') && !$(this).hasClass('active')) ||
                        (pageNumber === lastItem && !$(this).hasClass('last-item') && !$(this).hasClass('active'))) {
                        $(this).hide();
                    }
                });

                paginationWrap.parent().find('.pagination').show();
            } else {
                paginationWrap.parent().find('.pagination').hide();
            }
        });
    }
}

window.updateLanguagesList = function () {
    let currentUrl = window.location.pathname + window.location.search;
    let activeLang = $('.jsChangeHref.active').data('code');

    if (currentUrl.startsWith('/' + activeLang + '/')) {
        currentUrl = currentUrl.substring(3);
    }

    $('.jsChangeHref').each(function () {
        let route = '/' + $(this).data('code') + currentUrl;
        $(this).attr('href', route);
    });
}


window.filterCountryList = function (e) {
    let filter = $(e.target).val().toUpperCase();
    $('.filter-result li').each(function () {
        let txtValue = $(this).text().toUpperCase();
        if (txtValue.indexOf(filter) > -1) {
            $(this).show();
            $(this).removeClass('noactive');
        } else {
            $(this).hide();
            $(this).addClass('noactive');
        }
    });

    let fullListSum = $('.filter-result li').length;
    let noactiveListSum = $('.filter-result li.noactive').length;
    if (fullListSum !== 0 && fullListSum == noactiveListSum) {
        $('.js-region-search-empty').removeClass('noactive');
    } else {
        $('.js-region-search-empty').addClass('noactive');
    }
}

// function UTCTime() {
//     var zone = '', temp = -(new Date()).getTimezoneOffset() / 60 ;
//     if (temp >= 0) zone += "+";
//     zone += (Math.abs(temp) < 1 ? "0" : (Math.abs(temp) < 10 ? "0" : "")) + temp + ":00";

//     if ($('.js-utc').length > 0) {
//         $('.js-utc').text('UTC '+zone);
//     }
// }

window.initInformerCopy = function () {
    informerCopyInit();
}

function informerCopyInit() {
    $(document).on('click', '.js-informer', informerCopy)
}

function informerCopy() {
    const parentEl = this.closest('.js-copy-button-wrap');
    const textElements = parentEl.querySelectorAll(".js-text-to-copy");

    let textToCopy = "";
    textElements.forEach((element) => {
        const dataCopyValue = element.getAttribute('data-copy');
        if (dataCopyValue !== null) {
            textToCopy += `${dataCopyValue.trim()}\n`;
        } else {
            const text = element.textContent.trim().replace(/\s+/g, ' ');
            if (text) {
                textToCopy += `${text}\n`;
            }
        }
    });

    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = textToCopy;

    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);

    $(this).find('.js-informer-message').addClass('active');
    setTimeout(function () {
        $('.js-informer-message').removeClass('active');
    }, 1000);
}

function copyAllTextInit() {
    $(document).on('click', '.js-copy-all-btn', copyAllTexts);
}

function copyAllTexts() {
    const parentEl = this.closest('.js-copy-all-container');
    const textElements = parentEl.querySelectorAll('.js-text-to-copy');

    let tempTextarea = document.createElement('textarea');
    let textToCopy = '';

    textElements.forEach((textEl, index) => {
        const dataCopyValue = textEl.getAttribute('data-copy');
        if (dataCopyValue !== null) {
            textToCopy += `${dataCopyValue.trim()}\n`;
        } else {
            const text = textEl.textContent.trim().replace(/\s+/g, ' ');
            if (text) {
                textToCopy += `${text}`;
                if (textEl.classList.contains('js-text-to-copy-space')) {
                    textToCopy += '\n\n';
                } else {
                    textToCopy += '\n';
                }
            }
        }
    });

    tempTextarea.value = textToCopy;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextarea);

    if ($('.js-copy-all-container').find('.js-copy-container')) {
        $('.js-copy-container').addClass('active')
        $('.js-load-progress').animate({
            width: '100%',
        }, 4000, function () {
            $('.js-copy-container').removeClass('active')
            $('.js-load-progress').css('width', 0)
        })
        $('.js-copy-container-close').on('click', function () {
            $('.js-copy-container .js-load-progress').stop().css('width', 0)
            $('.js-copy-container').removeClass('active')
        })
    }
}

window.CopyToClipboard = function (containerid, alternative) {
    var copytext = document.createElement('input');
    if (alternative) {
        copytext.value = $(containerid).val();
    } else {
        copytext.value = $('#' + containerid).text().trim();
    }

    document.body.appendChild(copytext);
    copytext.select();
    document.execCommand('copy');
    document.body.removeChild(copytext);
}

window.CopyLinkToClipboard = function (containerid) {
    var copytext = document.createElement('input');
    copytext.value = 'https://' + domain + $('#' + containerid).attr('href');
    document.body.appendChild(copytext);
    copytext.select();
    document.execCommand('copy');
    document.body.removeChild(copytext);
}

window.setCookie = function (name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

window.getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

window.updateMiniCarts = function (count, itemId) {
    var data = {};
    if (count != null && itemId != null) {
        data = {
            count: count,
            itemId: itemId
        };
    }
    if ($('.js-cart-scroll-parent').find('.js-cart-scroll-block').length) {
        var currentPosition = $('.js-cart-scroll-block').scrollTop();
    }
    if (count == 0) {
        $('.offers').find('#' + itemId).removeClass('card-added');
        window.dispatchEvent(new CustomEvent('updateIsAddedToCart', { detail: { itemId: itemId } }));
    }

    $.ajax({
        type: "GET",
        url: $('#cardModal').attr('data-action'),
        data: data,
        success: function (data) {
            var count = parseInt(getClearValue($(data).find('.cart-count').html()));
            $('.cart-button .cart-cost').html($(data).find('.cart-price').html());
            let mobileCartButton = $('.mobile-cart-button');
            if (count >= 1) {
                $('.cart-button-mobile .cart-text').addClass('active');
                $('.cart-button-mobile .cart-text').html(number_format(count));
                $('.cart-button .cart-text').addClass('active');
                $('.cart-button .cart-text').html(number_format(count));
                $('.cart-cost-info').removeClass('hide');
                checkMiniCartBtn();
                //$('.js-mini-cart-link').attr('href', '/cart');
                //$('.js-mini-cart-link').off('click');
            } else {
                $('.cart-button-mobile .cart-text').removeClass('active');
                $('.cart-button-mobile .cart-text').html(number_format(count));
                $('.cart-button .cart-text').removeClass('active');
                $('.cart-button .cart-text').html(number_format(count));
                checkMiniCartBtn();
            }

            $('.mini-cart').html($(data).find('.mini-card'));
            $('.mini-cart-moby .wrap-cart').html($(data).find('.mini-card'));
            $('#cardModal .js-popup-cart-wrap').html($(data).find('.modal-card'));

            if (count == 1) {
                $('.mobile-cart-text').text(mobileCartButton.attr('data-item'))
            } else {
                $('.mobile-cart-text').text(mobileCartButton.attr('data-items'))
            }

            if (count == 0) {
                $('.cart-button').removeClass('active');
                $('.mini-cart').removeClass('active').addClass('null');
                // $('.mini-cart').hide();
                $('.cart-cost-info').addClass('hide');
                $('#cardModal').modal('hide');
                $('.background-page').removeClass('active');
                $('body').removeClass('noscroll');
            }
            
            if(!adaptive) {
                cartScrollShadow();
            }
            
            listScrollShadow();
            btnPreloaderOnClick();

            $('#cardModal').css('margin-top', -($('#cardModal').height() / 2));

            $('.js-cart-scroll-block').scrollTop(currentPosition);
        }
    });
}

window.tagTracking = function tagTracking(type, data = false) {

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({ ecommerce: null });

        if (typeof data === 'string') {
            data = JSON.parse(data);
        }

        switch (type) {
            case 'registration':
                dataLayer.push({ 'event': 'CompleteRegistration' });
                break;
            case 'authorize':
                dataLayer.push({ 'event': 'Auth' });
                break;
            case 'addToCard':
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        items: data
                    }
                });
                break;
            case 'buyNow':
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        items: data
                    }
                });
                break;
            case 'checkout':
                dataLayer.push({
                    event: "begin_checkout",
                    ecommerce: {
                        items: data
                    }
                });
                break;
            case 'confirmPay':
                dataLayer.push({
                    event: "checkout_progress",
                    ecommerce: {
                        items: data
                    }
                });
                break;
            case 'successPurchase':
                dataLayer.push({
                    event: "purchase",
                    ecommerce: data
                });
                break;
            case 'socialAuth':
                dataLayer.push({ 'event': 'SocialAuth' });
                break;
            case 'removeFromCart':
                dataLayer.push({
                    event: "remove_from_cart",
                    ecommerce: {
                        items: data
                    }
                });
                break;
            case "viewOffers":
                dataLayer.push({
                    event: "select_item",
                    ecommerce: {
                        items: data
                    }
                });
                break;
            case "viewProducts":
                dataLayer.push({
                    event: "view_item_list",
                    ecommerce: {
                        items: data
                    }
                });
                break;
        }
    }
}
window.initSelectmenu = function () {
    if (adaptive) {
        $('.backward').on('click touch', function () {
            $(this).closest('.tab-content').addClass('active')
        });
        var index = $('.offer-index .general-menu li.active').index();
        $('.offer-index .general-menu').slick({
            dots: false,
            infinite: true,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            initialSlide: index,
            nextArrow: '<a type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        });

        var index = $('.product-create .lang-list li.active').index();
        $('.product-create .lang-list').slick({
            dots: false,
            infinite: true,
            centerMode: true,
            variableWidth: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            initialSlide: index,
            nextArrow: '<a type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        });

        var index = $('.offer-list .general-sub-menu li.active').index();
        $('.offer-list .general-sub-menu').slick({
            dots: false,
            infinite: true,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            initialSlide: index,
            nextArrow: '<a type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        });


        var index = $('.offer-page.offer-list .nav-tabs.general-menu li.active').index();
        $('.offer-page.offer-list .nav-tabs.general-menu').slick({
            dots: false,
            infinite: true,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            initialSlide: index,
            nextArrow: '<a type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        });


        var index = $('.statistic-tables .reflink-slider .nav-tabs li.active').index();
        $('.statistic-tables .reflink-slider .nav-tabs').slick({
            dots: false,
            infinite: true,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            initialSlide: index,
            nextArrow: '<a type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        });

        var index = $('.discount-multi-level-page .general-menu li.active').index();
        $('.discount-multi-level-page .general-menu').slick({
            dots: false,
            infinite: true,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            initialSlide: index,
            nextArrow: '<a type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        });

        var index = $('#offer-create .translation-tabs.lang-list li.active').index();
        $('#offer-create .translation-tabs.lang-list').slick({
            dots: false,
            infinite: true,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            initialSlide: index,
            nextArrow: '<a type="button" class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
            prevArrow: '<a type="button" class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        });
    }
}

window.initSpan = function () {
    $('.checkbox-group .checkbox-body .checkbox label').each(function () {
        var html = $(this).html() + '<span></span>';
        $(this).html(html);
    });

    $('.radiobox-group .radiobox-body label').each(function () {
        var html = $(this).html() + '<span></span>';
        $(this).html(html);
    });
}

function handleMobileSearchForm() {
    const searchForm = $('.header-inner__navbar-search');
    const breakpoint = 768;

    function moveSearchForm() {
        if ($(window).width() < breakpoint) {
            let search = searchForm.clone();
            search.find('.header-inner__category').addClass('mobile').removeClass('desktop');
            search.addClass('mobile');
            search.appendTo('.header-inner__row');
            searchForm.remove();
        }
    }

    moveSearchForm();
}

var currentRequest = null;
var searchTimeout = null;

function requestForSearchHeaderInput(input) {
    let value = $(input).val();

    if (!value.trim()) {
        $('.js-header-search,.header-inner__search-submit').removeClass('preloaded');
        $('.search-result.for-product-search span').hide();
        //$('.background-page').removeClass('active');
        return;
    }

    $('.js-header-search').addClass('active');
    $('.header-inner__search-submit').addClass('preloaded');
    $('.search-result').slideUp(100);

    if (parseInt(value.length) > 2) {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(function () {
            var request = {
                'name': value,
                'per-page': 20,
                'ajax': 1
            };

            if (currentRequest) {
                currentRequest.abort();
            }

            currentRequest = $.ajax({
                'url': $(input).attr('data-url'),
                'data': request,
                'success': function (data) {
                    if ($('.background-page').is(':visible') && parseInt($('header #search input[name=name]').val().length) > 2) {
                        $('.search-result').slideDown(100);
                        $('.search-result').html(data);
                        $('.for-product-search span').show();
                        var htmlForSpan = $(data).find('.placeholder-class').html();
                        $('.for-product-search span').html(htmlForSpan);
                        $('#search .search-form').val($('#search .search-form').val());
                        $('.header-inner__search-submit').removeClass('preloaded');

                        checkModalSize($('.wrap-ajax'));
                        
                        $('.wrap-ajax').on('scroll', function () {
                            checkModalSize($(this))
                        });

                        if ($('.count-list .item-list >div').length) {
                            $('.search-result').removeClass('empty')
                        } else {
                            $('.search-result').addClass('empty')
                        }
                        //$('.background-page').addClass('active');
                    } else {
                        $('.js-header-search').removeClass('active');
                        $('.header-inner__search-submit').removeClass('preloaded')
                        //$('.background-page').removeClass('active user-menu');
                    }
                    $('.mini-chat-wrap, #launcher').addClass('is--hidden-mobile');
                    //$('body').addClass('noscroll');

                },
                'error': function (jqXHR) {
                    if (jqXHR.statusText !== "abort") {

                    }
                },
                'complete': function () {
                    currentRequest = null;
                }
            });
        }, 300);
    } else {
        $('.js-header-search').removeClass('active');
        $('.header-inner__search-submit').removeClass('preloaded')
        //$('.background-page').removeClass('active');
        $('.search-result').slideUp(100);
        $('.for-product-search span').hide();
    }
}

// function changeBlurWidth(){
//     let blurElement = $('.header-inner__catalog-blur')
//     let width = 0
//     $('.sub-header__category').each(function(){
//         if($(this).is(':visible')){
//             width += $(this).width()
//         }
//     })
//     blurElement.width(width)
// }

function setViewedOrders(order_id) {
    let viewed_orders = JSON.parse(getCookie('viewed_orders'));
    if (viewed_orders == null) viewed_orders = [];
    let index = viewed_orders.indexOf(order_id);
    if (index == -1) {
        viewed_orders.push(order_id);
    }
    setCookie('viewed_orders', JSON.stringify(viewed_orders));
    return viewed_orders;
}

let notificationTimerID = '';

function notificationsHide() {
    $('.push_notification').css('bottom', '0px').animate({ bottom: '-200px' }, 500, 'linear');
    $('.push_notification_container').removeClass('visible');
    //notificationTimerID = setTimeout(() => notificationsShow(), 5000);
}

function notificationsShow() {
    if ($('.push_notification_container').hasClass('hide')) {
        return false;
    }

    /*$.ajax({
        url: "/order/notifies",
        success: function (html) {
            $('.push_notification_container').html(html);
            $('.push_notification').css('bottom','-200px').animate({bottom:'0px'},500,'linear')
             notificationsHideTimer();
        }
    })*/
}


function notificationsHideTimer() {
    notificationTimerID = setTimeout(() => notificationsHide(), 10000);
}

function CopyToClipboard(containerid, alternative) {
    var copytext = document.createElement('input');
    if (alternative) {
        copytext.value = $(containerid).val();
    } else {
        copytext.value = $('#' + containerid).html();
    }
    document.body.appendChild(copytext);
    copytext.select();
    document.execCommand('copy');
    document.body.removeChild(copytext);
}

function showProductDescription() {
    $(document).on('click', '.js-product-descr-btn', function () {
        let infoDropdown = $(this).parents('.js-product-descr-container').find('.js-product-descr-info');

        $(this).css('pointer-events', 'none');

        setTimeout(() => {
            $(this).css('pointer-events', 'all');
        }, 500);

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(infoDropdown).removeClass('active');
            $(infoDropdown).slideUp("slow");
        } else {
            $(this).addClass('active');
            $(infoDropdown).addClass('active');
            $(infoDropdown).slideDown("slow");
        }
    })
}

function updateMobileCartText() {
    let cartMobileButton = $('.mobile-cart-button');
    let cartCount = Number(cartMobileButton.find('.cart-text').html());
    let mobileCartText = cartMobileButton.find('.mobile-cart-text');

    if (cartCount === 1) {
        mobileCartText.text(cartMobileButton.data('item'));
    } else if (cartCount === 0) {
        cartMobileButton.find('.cart-cost-info').addClass('hide');
    }
    else {
        mobileCartText.text(cartMobileButton.data('items'));
    }
}

function checkMiniCartBtn() {
    const miniCartBtn = document.querySelectorAll('.js-mini-cart-btn');
    miniCartBtn.forEach(function (button) {
        const miniCartBtnInformer = button.querySelector('.js-cart-informer');
        const cartCostElement = button.querySelector('.js-cart-cost');

        if (!cartCostElement) {
            console.error('Cart cost element not found');
            return;
        }

        const cartCostText = cartCostElement.textContent.trim();
        const miniCartBtnLink = button.querySelector('.js-mini-cart-link');

        if (!miniCartBtnLink) {
            console.error('Mini cart button link not found');
            return;
        }

        const price = parseFloat(cartCostText.replace(/[^\d.]/g, ''));

        if (price > 0) {
            button.classList.add('active');
            if (miniCartBtnInformer) {
                miniCartBtnInformer.classList.add('active');
            }
            miniCartBtnLink.onclick = null;
        } else {
            button.classList.remove('active');
            const backgroundWrapper = document.querySelector('.background-wrapper--mini-cart');
            if (backgroundWrapper) {
                backgroundWrapper.classList.remove('active');
            }
            if (miniCartBtnInformer) {
                miniCartBtnInformer.classList.remove('active');
            }
            miniCartBtnLink.href = '#';
            miniCartBtnLink.onclick = function (event) {
                event.preventDefault();
            };
        }
    });
}

function regulateHelpBlock(rowContainer) {
    if ($(window).width() > 768) {
        $(rowContainer).find('.col-md-6').each(function (index, el) {
            if ($(el).find('.help-block').html()) {
                if (index % 2 === 0) {
                    var helpBlock = $(rowContainer).find('.col-md-6').eq(index + 1).find('.help-block');
                    if (!helpBlock.html().length) {
                        helpBlock.html('&nbsp;');
                    }
                }
            }
        });
    }
}

function resizeElements() {
    let widthItem = $(window).width() - 95;
    let getItemText = $('.template-payout-modal .widget-copy > span:not(.copy-button)');

    if (adaptive) {
        if (getItemText.length) {
            $('.template-payout-modal .widget-copy > span:not(.copy-button)').css({
                'max-width': `${widthItem}px`
            });
        }
    }

    if ($(this).height() > $(window).height()) {
        $(this).css({
            'position': 'absolute',
            'transform': 'translate(-50%, 0)',
            'left': '50%',
            'top': '0'
        });
    }

}


if (!$('.page-wrap').is('.shopping-cart-page') && !$('.page-wrap').is('.checkout-page')) {
    $(document).on('click', '.js-mini-cart-btn.active .js-mini-cart-link', function (e) {
        e.stopPropagation();
        e.preventDefault();

        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            $(this).closest('.js-mini-cart-btn').find('.js-mini-cart').addClass('active');
            $('body').addClass('noscroll');
            $('.background-page').addClass('active');

        } else {
            $(this).closest('.js-mini-cart-btn').find('.js-mini-cart').removeClass('active');
            $('.background-page').removeClass('active user-menu');
            $('body').removeClass('noscroll');
        }
    });

    $(document).on('click', '.mini-cart__close', function (e) {
        e.preventDefault();
        $('.background-page').removeClass('active user-menu');
        $('.js-mini-cart-link').removeClass('active');
        $('.js-mini-cart').removeClass('active');
        $('body').removeClass('noscroll');
    });
}

if (adaptive) {
    $(document).on('click', '.footer-inner__menu-title', function () {
        var $menuTitle = $(this);
        var $menuWrap = $menuTitle.closest('.footer-inner__menu-wrap');
        var $menuList = $menuWrap.find('.footer-inner__menu-list');
        $menuTitle.css('pointer-events', 'none');
        setTimeout(() => { $menuTitle.css('pointer-events', 'all') }, 500);

        $menuTitle.toggleClass('active');
        $menuList.slideToggle(500).toggleClass('active', $menuList.is(':visible'));
    });
}

$(document).ready(function () {
    if ($('.push_notification_container').length) {
        let lang = $('html').attr('lang');
        let user_id = $('input[type="hidden"][name="user_id"]').val();
        socket = io('ws.difmark.com', {
            reconnectionDelay: 10000,
            reconnection: true,
            reconnectionAttemps: 5,
            transports: ['websocket'],
            agent: false,
            upgrade: false,
            rejectUnauthorized: false,
            query: { 'lang': lang, 'user_id': user_id }
        });

        socket.on('notifies', (data) => {
            $('.push_notification_container').html(data.message);
            $('.push_notification').css('bottom', '-200px').animate({ bottom: '0px' }, 500, 'linear');
            $('.push_notification_container').addClass('visible');
            if (data.auto_close) {
                notificationsHideTimer();
            } else {
                clearTimeout(notificationTimerID);
            }
        });
    }

    let buttonCatalogWidth = $('.header-inner__category.desktop').outerWidth();

    // $('.sub-header__item-link-first').on({
    //     mouseenter: function() {

    //         let getParentItem = $('.sub-header__category.first');
    //         let attr = $(this).attr('data-id');

    //         getParentItem.show();

    //         getParentItem.find('li:not(.sub-header__category-link)').each(function(){
    //             $(this).toggle($(this).attr('data-id') === attr);
    //         });

    //         $(this).closest('.sub-header__blur').addClass('active');
    //         $(this).closest('.sub-header__category').addClass('active');

    //         $(this).addClass('active');
    //         $(this).parent().siblings().find('a').removeClass('active');   
    //         $('.sub-header__category.last').hide();
    //         checkModalSize($('.sub-header__list-fade'));

    //         //changeBlurWidth()
    //     }
    // });

    $('.sub-header__list-fade').on('scroll', function () {
        checkModalSize($(this));
    });

    $('.modal-height').on('scroll', function () {
        checkModalSize($(this));
    });

    $('.sub-header__list-fade-last').on('scroll', function () {
        checkModalSize($(this));
    });

    $('.sub-header__sub-menu-link-hover').on({
        mouseenter: function () {

            let getParentItem = $('.sub-header__category.last');
            let attr = $(this).attr('data-id');

            getParentItem.show();

            getParentItem.find('li:not(.sub-header__category-link)').each(function () {
                $(this).toggle($(this).attr('data-id') === attr);
            });

            $(this).addClass('active');
            $(this).parent().siblings().find('a').removeClass('active');
            $(this).closest('.sub-header__blur').addClass('active');
            $(this).closest('.sub-header__category').addClass('active');
            checkModalSize($('.sub-header__list-fade-last'));

            //changeBlurWidth()
        }
    });


    $('.header-inner__search-placeholder').css('padding-left', Math.round(buttonCatalogWidth + 12) + 'px');
    $('.header-inner__search-input').css('padding-left', Math.round(buttonCatalogWidth + 10) + 'px');

    $('.header-inner__search-placeholder').removeClass('is--default-hide');


    $(document).on('submit', '#search', function () {
        $(this).find('[type="submit"]').addClass('preloaded');
    });

    if ($(window).width() < 1200) {
        var slider = $('.footer-inner__payment-logos');

        slider.slick({
            infinite: true,
            slidesToShow: 10,
            centerMode: true,
            slidesToScroll: 'auto',
            swipeToSlide: false,
            arrows: false,
            variableWidth: true,
            autoplay: true,
            autoplaySpeed: 1,
            speed: 5000,
            cssEase: 'linear',
            draggable: false,
            waitForAnimate: false,
            pauseOnFocus: true,
            pauseOnHover: true,
        });
    }

    let previousScrollHeader = 0;
    let previousScrollSubHeader = 0;
    let isHeaderDisabled = $('header').hasClass('header-full') ? true : false;
    let offsetHeader = $('.header-inner').offset();
    let offsetSubHeader = $('.header__sub-row').offset();

    if (!isHeaderDisabled) {
        if (offsetHeader && offsetHeader.top > 15) {
            $('.header-inner').addClass('header-inner__sticky');
        }

        setTimeout(function () {
            $('.header-inner.animated').removeClass('animated')
        }, 1500)

        if (offsetSubHeader && offsetSubHeader.top > 95) {
            $('.header__sub-row').addClass('header__sub-row__sticky');
        }

        $(window).scroll(function () {

            let scroll = $(this).scrollTop();

            if (scroll > 15) {
                if (scroll > previousScrollHeader) {
                    $('.header-inner').addClass('header-inner__sticky');
                }
            }
            else {
                $('.header-inner').removeClass('header-inner__sticky');
            }
            previousScrollHeader = scroll;
        });

        $(window).scroll(function () {
            let scroll = $(this).scrollTop();

            if (scroll > 15) {
                if (scroll > previousScrollSubHeader) {
                    $('.header__sub-row').addClass('header__sub-row__sticky');
                }
            }
            else {
                $('.header__sub-row').removeClass('header__sub-row__sticky');
            }
            previousScrollSubHeader = scroll;
        });
    }

    $(document).on('click', '.nav-tabs a[data-toggle=tab]', function (e) {
        $(this).closest('.nav-tabs').find('a[data-toggle=tab]').each(function () {
            $(this).parent().removeClass('active');
        });
        $(this).parent().addClass('active');
    });

    if ($(window).width() > 767) {
        $(document).on('click', '.nav-tabs-tablet a', function () {
            let getAttr = $(this).attr('href');
            
            if (getAttr) {
                let targetId = getAttr.split('#')[1];
    
                $(this).closest('.notification-wrap').find('.notification-body').each(function () {
                    $(this).removeClass('active');
                    if ($(this).attr('id') === targetId) {
                        $(this).addClass('active');
                    }
                });
            }
        });
    }

    $(document).on('click', '.header-inner__search-button', function () {
        $(this).toggleClass('active');
        $('.sub-header').slideUp(500);
        $('.js-mob-menu-btn').removeClass('active')
        $('.js-user-menu-container').removeClass('active')
        $('.header-inner__category').removeClass('active');
        if ($(this).hasClass('active')) {
            //$('.background-page').addClass('active');
            $('.header-inner__navbar-search.mobile').slideDown(500);
            $('header').addClass('is--active');
            //$('body').addClass('noscroll');
            $('.mini-chat-wrap').addClass('is--hidden-mobile');
            $('#launcher').addClass('is--hidden-mobile');

            disablePageScroll();
        } else {
            $('header').removeClass('is--active');

            $('.header-inner__navbar-search.mobile').slideUp(500);
            $('.background-page').removeClass('active user-menu');
            $('.mini-chat-wrap').removeClass('is--hidden-mobile');
            $('#launcher').removeClass('is--hidden-mobile');
            //$('body').removeClass('noscroll');
            enablePageScroll();
        }
    });

    $(document).on('click', '.header-inner__category', function () {
        $(this).toggleClass('active');
        if (!$(this).is('.mobile')) {
            if ($(this).hasClass('active')) {
                $('body').addClass('noscroll')
            } else {
                $('body').removeClass('noscroll')
            }
        }
    });

    $(document).on('click', '.header-inner__category.desktop', function () {
        $('.header-inner__catalog-list.desktop,.header-inner__catalog-blur').removeClass('active');
        $('.sub-header__category.first').hide();
        $('.sub-header__category.last').hide();
        $('.sub-header__category a').removeClass('active');
        $('.sub-header__blur').removeClass('active');
        $('.sub-header__category').removeClass('active');


        if ($(this).hasClass('active')) {
            $('.header-inner__catalog-list.desktop,.header-inner__catalog-blur').addClass('active');
            $('.background-page').addClass('active');
            disablePageScroll();

        } else {
            $('.header-inner__catalog-list.desktop,.header-inner__catalog-blur').removeClass('active');
            $('.background-page').removeClass('active user-menu');
            enablePageScroll();
        }

        //changeBlurWidth()
    });

    $(document).on('click', '.header-inner__category.mobile', function () {
        if ($(this).hasClass('active')) {
            $('.sub-header').slideDown(500);
            $('.sub-header').addClass('active');
        } else {
            $('.sub-header').slideUp(500);
            $('.sub-header').removeClass('active');
        }
    });

    $(document).on('click', '.js-open-menu', function (e) {
        e.preventDefault();

        const $parent = $(this).parent();

        if ($parent.hasClass('active')) {
            $parent.removeClass('active');
            $parent.find('.sub-header__menu-mobile-second').slideUp(500);
        } else {
            $parent.siblings().removeClass('active');
            $parent.siblings().find('.sub-header__menu-mobile-second').slideUp(500);
            $parent.addClass('active');
            $parent.find('.sub-header__menu-mobile-second').slideDown(500);
            $parent.find('.sub-header-sub__item-mobile').removeClass('active');
        }


        $parent.siblings().find('.sub-header__menu-mobile-last').slideUp(500);
        $parent.find('.sub-header__menu-mobile-last').slideUp(500);

    });

    $(document).on('click', '.js-open-sub-menu', function (e) {
        e.preventDefault();

        const $parent = $(this).parent();

        if ($parent.hasClass('active')) {
            $parent.removeClass('active');
            $parent.find('.sub-header__menu-mobile-last').slideUp(500);
        } else {
            $parent.siblings().removeClass('active');
            $parent.siblings().find('.sub-header__menu-mobile-last').slideUp(500);

            $parent.addClass('active');
            $parent.find('.sub-header__menu-mobile-last').slideDown(500);
        }
    });


    // $('.sub-header__menu-mobile-second').scroll(function(){
    //     checkModalSize($(this));
    // });

    $(document).mouseup(function (e) {
        let categoryButton = $(".header-inner__category.desktop");
        let catalogWrapperDesktop = $('.sub-header__category');
        let userMenu = $('.user-menu');
        //let catalogList = $('.header-inner__catalog-list');
        if (
            !categoryButton.is(e.target) && categoryButton.has(e.target).length === 0
            && !catalogWrapperDesktop.is(e.target) && catalogWrapperDesktop.has(e.target).length === 0
            && !userMenu.is(e.target) && userMenu.has(e.target).length === 0
        ) {

            $('.header-inner__catalog-list.desktop,.header-inner__catalog-blur').removeClass('active');
            $('.sub-header__category.first').hide();
            $('.sub-header__category.last').hide();
            $('.sub-header__category a').removeClass('active');
            $('.sub-header__blur').removeClass('active');
            $('.sub-header__category').removeClass('active');
            categoryButton.removeClass('active');
        }

        // Check for js-mini-cart
        let miniCart = $('.js-mini-cart');
        let cartLink = $('.js-mini-cart-link');
        if (!miniCart.is(e.target) && miniCart.has(e.target).length === 0 && !cartLink.is(e.target) && cartLink.has(e.target).length === 0) {
            miniCart.removeClass('active');
            cartLink.removeClass('active');
        }

        // Check for #search
        let searchDiv = $("#search");
        let resultSearch = $('.search-result');
        if (!searchDiv.is(e.target) && searchDiv.has(e.target).length === 0
            && !resultSearch.is(e.target) && resultSearch.has(e.target).length === 0
        ) {
            $('.search-result').hide();
            $('.mini-chat-wrap, #launcher').removeClass('is--hidden-mobile');
        }

        //Check search button 
        let searchButton = $('.header-inner__search-button');
        let searchWrapper = $('.header-inner__navbar-search.mobile');
        let catalog = $('.sub-header.mobile');
        if (
            !searchButton.is(e.target) && searchButton.has(e.target).length === 0
            && !searchWrapper.is(e.target) && searchWrapper.has(e.target).length === 0
            && !catalog.is(e.target) && catalog.has(e.target).length === 0
        ) {
            $('.header-inner__navbar-search.mobile').slideUp(500);
            catalog.slideUp(500);
            searchButton.removeClass('active');
            $('.mini-chat-wrap').removeClass('is--hidden-mobile');
            $('#launcher').removeClass('is--hidden-mobile');
        }

        //Check mob menu btn 
        let mobBtnMenu = $('.js-mob-menu-btn');
        if (!mobBtnMenu.is(e.target) && mobBtnMenu.has(e.target).length === 0 && !userMenu.is(e.target) && userMenu.has(e.target).length === 0 && $('.mini-icon').has(e.target).length) {
            mobBtnMenu.removeClass('active');
            userMenu.removeClass('active');
        }

        let category = $('.header-inner__category');
        let mobCategoryItem = $('.sub-header');
        let notificationWrapper = $(".notification-wrap");

        if (!category.is(e.target) && category.has(e.target).length === 0
            && !userMenu.is(e.target) && userMenu.has(e.target).length === 0
            && !mobCategoryItem.is(e.target) && mobCategoryItem.has(e.target).length === 0
            && !notificationWrapper.is(e.target) && notificationWrapper.has(e.target).length === 0
            && !searchWrapper.is(e.target) && searchWrapper.has(e.target).length === 0
            && !miniCart.is(e.target) && miniCart.has(e.target).length === 0
            && !catalogWrapperDesktop.is(e.target) && catalogWrapperDesktop.has(e.target).length === 0

        ) {

            $('.header-inner').removeClass('is--active');
            if($(window).width() > 768){
                //$('body').removeClass('noscroll');
            }
            $('.background-page').removeClass('active user-menu');
            enablePageScroll();
        }

    });


    $(document).on('submit', 'form[data-pjax-form]', function (event) {
        var options = $(this).data('pjax-form');
        $.pjax.submit(event, options);
    })

    $(document).on('click', '.background-page', function () {
        const userMenuBtn = $('.js-user-menu-btn');
        const userMenuContainer = $('.js-user-menu-container');

        userMenuBtn.removeClass('active');
        userMenuContainer.removeClass('active');
        $('.background-page').removeClass('active user-menu');
        $('.search-result').slideUp(100);
        $('.header-inner__catalog-list.desktop,.header-inner__catalog-blur').removeClass('active');
        $('.header-inner__category').removeClass('active');
        $('.for-product-search span').hide();
        $('.header-inner__search-submit').removeClass('preloaded')
        $('.mini-chat-wrap, #launcher').removeClass('is--hidden-mobile');
        $('body').removeClass('noscroll');
        $('.mini-chat-wrap').removeClass('is--hidden');
        enablePageScroll();
    });

    $(document).on('click', 'a[data-pjax-link]', function (event) {
        var options = $(this).data('pjax-link');
        $.pjax.click(event, options);
    })

    $(document).on('click', '.copy-button', function () {
        let copyButton = $(this)
        CopyToClipboard($(this).attr('data-copy-id'));
        $(copyButton).find('.informer').css({ opacity: 1, display: 'block' });
        $(copyButton).find('.informer').stop(true, true)
        setTimeout(function(){
            $(copyButton).find('.informer').animate({
                opacity: 0,
            }, 1000, function () {
                copyButton.find('.informer').css({ display: 'none'});
            })
        },500)
    });

    $(document).on('click', '.js-informer', function () {
    });

    $(document).on('click', '.sub-header__category-link', function () {
        $(this).addClass('preloader')
        setTimeout(() => { $(this).removeClass('preloader') }, 1300)
    });

    $(document).on('click', '.sub-header__close-block', function () {
        $(this).closest('.sub-header.mobile').removeClass('active');
        $('.header-inner__category.mobile').removeClass('active')
        $(this).closest('.sub-header.mobile').slideUp(500);
    });

    $(document).on('click', 'button', function () {
        if (!$(this).is('.slick-arrow') && !$(this).is('.switch-disabled')) {
            var button = this;
            setTimeout(function () {
                $(button).prop('disabled', true);
            }, 1);
            setTimeout(function () {
                $(button).prop('disabled', false);
            }, 2000);
        }
    });

    $('.custom-select').customSelect2();
    $('.custom-alerts .custom-alert').customAlert('init');

    $('.mini-cart').on('DOMSubtreeModified', function () {
        if ($(this).find('.list-items').length == 0) {
            $(this).addClass('null')
        } else {
            $(this).removeClass('null')
        }
    })

    initSpan();


    $(document).on('click.bs.dropdown.data-api', '.user-dropdown.dropdown-menu .fas', function (e) {
        e.stopPropagation()
    });

    $('.search-form').on("click", (function (e) {

        $('.sub-header.mobile').slideUp(500);
        $('.header-inner__category').removeClass('active');
        //$('.background-wrapper--opacity').removeClass('active');

        if ($(e.target).is(".close")) {
            $(".form-group").removeClass("sb-search-open");
            $('.search-result').hide();
            //$('.background-wrapper').removeClass('active');
            $('.mini-chat-wrap, #launcher').removeClass('is--hidden-mobile');

        } else {
            $(".form-group").addClass("sb-search-open");
        }
        e.stopPropagation()
    }));



    if ($('.countdown .clock').length) {
        $('.countdown .clock').each(function () {
            var second = parseInt($(this).attr('data-second')) * 1000;
            var newDate = new Date().getTime() + parseInt(second);
            $('#' + $(this).attr('id')).countdown(newDate)
                .on('update.countdown', function (event) {
                    var totalHours = event.offset.totalDays * 24 + event.offset.hours;
                    $(this).html(event.strftime(
                        '<span class="hour">' + totalHours + '</span>'
                        + '<span class="minute">%M</span>'
                        + '<span class="second">%S</span>'));
                })
                .on('finish.countdown', function (event) {
                    $(this).parent().hide();
                    $($(this).attr('data-ready')).show();
                });
        });
    }

    var headerInput = $('header #search input[name=name]'), timeOut;
    headerInput.on('input', function () {

        if ($(this).val().trim() !== '') {
            requestForSearchHeaderInput($(this));
            $(this).closest('.header-inner__search-wrap').find('.header-inner__search-placeholder').addClass('is--hide');
            $('.background-page').addClass('active');
            $('body').addClass('noscroll');
        } else {
            $(this).closest('.header-inner__search-wrap').find('.header-inner__search-placeholder').removeClass('is--hide');
            $('.search-result').hide();
            $('.background-page').removeClass('active');
            $('body').removeClass('noscroll');
        }

        //clearTimeout(timeOut);
        //$('.for-product-search span').hide();
        //$('.search-result').hide();
        //$('.background-wrapper').hide();
        //        timeOut = setTimeout(requestForSearchHeaderInput, 1000, $(this));
    });



    //    headerInput.on('keydown', function () {
    //        clearTimeout(timeOut);
    //    });

    onPjaxSuccess()

    function onPjaxSuccess() {
        var defaultMaxCharacters = 1000
        $(document).on('keyup ready', '.chat-block #user-input, #ticket-description,#ticket-textarea,.dashboard-description,.news-comment', function trimLength() {
            var maxLength = $(this).attr('maxlength') ? $(this).attr('maxlength') : defaultMaxCharacters;
            var length = $(this).val().length;
            length = maxLength - length;
            $(this).closest('form').find('.char-left-container').find('span').text(length);
        });

        $(document).on('show.bs.modal', '.modal', function (e) {
            var el = $(this)
            setTimeout(function () {
                modalAddClass('animate')

                if ($(el).height() > $(window).innerHeight()) {
                    $(el).addClass('top-0')
                }
            }, 50)

            checkModalSize(el.find('.modal-dialog'))

            el.scroll(function () {
                checkModalSize(el)
            })
        })

        $('.help-block').on('DOMSubtreeModified', function () {
            // This DOMSubtreeModified event is exclusion 'cause we need to handle error's which came from many sources  
            regulateHelpBlock($(this).closest('.row'))
        })

        $(document).on('shown.bs.modal', '.modal', function (e) {

            modalAddClass('animate')
            modalAddClass('showed')

            resizeElements.call(this);

            $(window).on('resize', function () {
                resizeElements.call(this);
            });

            initInputPlaceholderPadding()
            initInputRestrinction()
        })

        let isMousePressedInsideModal = false;

        $(document).on('mousedown', '.modal', function (e) {
            isMousePressedInsideModal = true;
        });

        $(document).on('mouseup', '.modal', function (e) {
            isMousePressedInsideModal = false;
        });

        $(document).on('hidden.bs.modal', '.modal', function () {
            if (isMousePressedInsideModal) {
                isMousePressedInsideModal = false;
                return;
            } else {
                $('body').removeClass('modal-open');
                $('body').removeClass('page-overflow');
                $('.modal-backdrop, .modal-scrollable').hide();
            }
        });

        $(document).on('hide.bs.modal', '.modal', function (e) {
            if (isMousePressedInsideModal) {
                isMousePressedInsideModal = false;
                return e.preventDefault();
            } else {
                $('.modal-backdrop, .modal-scrollable').removeClass('animate showed');
            }
        });

        $('input').on('keydown', function (event) {
            const ctrlKey = event.ctrlKey || event.metaKey;
            const keyCode = event.which;

            if (ctrlKey && (keyCode === 86 || keyCode === 88)) {
                if (ctrlKey && (keyCode === 88 || keyCode === 86)) {
                    return;
                }

                if (ctrlKey && keyCode === 65) {
                    event.preventDefault();
                    $input.select();
                }
            }
        });
        if ($('.rocket').length) {
            const rocketButton = $('.rocket')
            $(document).on('scroll', throttle(function () {
                if ($(document).scrollTop() > 200) {
                    rocketButton.addClass('active')
                } else {
                    rocketButton.removeClass('active')
                }

            }, 50))

            rocketButton.on('click', function () {
                $([document.documentElement, document.body]).animate({
                    scrollTop: 0
                }, 500);
            })
        }

        initInputPlaceholderPadding()
    }

    $(document).on('pjax:complete', function (event) {
        $(document).lazyLoadXT();
        $('.custom-select').customSelect2();
        $('.custom-alerts .custom-alert').customAlert('init');
        onPjaxSuccess()
    });

    $('img.data-fix-src').each(function () {
        if (adaptive && typeof $(this).attr('data-mobile-fix-src') !== "undefined") {
            $(this).prop('src', $(this).attr('data-mobile-fix-src'));
        } else {
            $(this).prop('src', $(this).attr('data-fix-src'));
        }
    });

    $(document).on('click touchstart', selectors.join(', '), function () {
        var count = parseInt(getClearValue($(this).parents('.count-wrap').find('.count').html()));
        var itemId = parseInt($(this).parents('.count-wrap').attr('data-item'));
    
        if ($(this).is('.up')) {
            count++;
        } else {
            count--;
        }
        if ($(this).is('.delete-icon')) {
            count = 0;
        }
        updateMiniCarts(count, itemId);
    });

    $(document).on('click', '.delete-icon-cart', function () {
        var itemId = parseInt($(this).parents('.catalog-card').attr('id'));
        $(this).addClass('preload');
        setTimeout(() => {
            $(this).removeClass('preload');
            updateMiniCarts(0, itemId);
        }, 400)
    });

    $('.view-block .add-to-cart').on('click', function () {
        $(this).hide();
        $(this).parents('.view-block').find('.card__button--added').show();
    });

    $('.view-block .add-to-cart').on('click', function () {
        $(this).hide();
        $(this).parents('.view-block').find('.js-card-button-added').show();
    });

    $(document).on('click', '*[data-tag-tracking=addToCard]', function (e) {
        e.preventDefault();
        var params = '';

        if ($(this).parents('form').find('input[name=amount]').is('*')) {
            params = 'amount=' + getClearValue($(this).parents('form').find('input[name=amount]').val());
        }
        if ($(this).parents('form').find('input[name=range_from]').length) {
            params += '&range_from=' + $(this).parents('form').find('input[name=range_from]').val();
        }
        if ($(this).parents('form').find('input[name=range_to]').length) {
            params += '&range_to=' + $(this).parents('form').find('input[name=range_to]').val();
        }
        var url = $(this).parents('form').attr('action');
        $.ajax({
            type: "POST",
            data: params,
            url: url,
            success: function () {
                updateMiniCarts(null, null);
                $('#cardModal').modal('show');
            }
        });
    });

    // header start

    $(document).on('click', function (e) {
        const userMenuBtn = $('.js-user-menu-btn');
        const userMenuContainer = $('.js-user-menu-container');

        if (userMenuContainer.is(e.target) || userMenuContainer.has(e.target).length > 0) {
            return;
        }

        if (!$(e.target).closest('.js-user-menu-container').length && userMenuBtn.hasClass('active')) {
            userMenuBtn.removeClass('active');
            userMenuContainer.removeClass('active');
            $('.background-page').removeClass('active');
        }
    });

    $('.js-user-menu-close-btn').on('click', function (e) {
        e.stopPropagation();

        const userMenuBtn = $('.js-user-menu-btn');
        const userMenuContainer = $('.js-user-menu-container');

        userMenuBtn.removeClass('active');
        userMenuContainer.removeClass('active');
        $('.background-page').removeClass('active user-menu');
        $('body').removeClass('noscroll');
        $('.mini-chat-wrap').removeClass('is--hidden');
    });

    $(document).on('click', '.js-user-menu-btn', function (e) {
        e.stopPropagation();

        const userMenuBtn = $(this);
        const userMenuContainer = $('.js-user-menu-container');

        userMenuBtn.addClass('active');
        userMenuContainer.addClass('active');
        $('.background-page').addClass('active user-menu');
        $('body').addClass('noscroll');

        $('.mini-chat-wrap').addClass('is--hidden');

        if ($('.mini-chat-wrap').hasClass('active')) {
            $('.mini-chat-wrap').removeClass('active');
        }
    });

    $(document).on('click', '.js-mob-acc-container .js-mob-acc-title', function () {
        let mobCatContainer = $(this).parents(".js-mob-acc-container");
        let mobCatParent = $(this).closest(".js-mob-acc-parent");
        let mobCatList = mobCatParent.find(".js-mob-acc-body");

        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            mobCatList.slideUp("300");
        } else {
            mobCatContainer.find(".js-mob-acc-title").removeClass("active");
            mobCatContainer.find(".js-mob-acc-parent").children(".js-mob-acc-body").slideUp("300");
            $(this).addClass("active");
            mobCatList.slideDown("300");
            let windowHeight = $(window).height();
            let scrollTop = $(this).offset().top;
            if (windowHeight / 2 < scrollTop) {
                let scrollPosition = $(this).offset().top + 300;
                $('.user-menu__wrap').animate({ scrollTop: scrollPosition }, 300);
            }
        }
    });

    $(document).on('click', '.js-sub-acc-container .js-sub-acc-title', function () {

        let mobSubCatContainer = $(this).parents(".js-sub-acc-container");
        let mobSubCatParent = $(this).closest(".js-sub-acc-parent");
        let mobSubCatList = mobSubCatParent.find(".js-sub-acc-body");

        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            mobSubCatList.slideUp("300");
        } else {
            mobSubCatContainer.find(".js-sub-acc-title").removeClass("active");
            mobSubCatContainer.find(".js-sub-acc-parent").children(".js-sub-acc-body").slideUp("300");
            $(this).addClass("active");
            mobSubCatList.slideDown("300");
        }
    });

    $('.multiple-items').on('click', function () {
        $(this).closest('li').toggleClass('active').find('.items-list').slideToggle()
    })

    $(document).on('click', '.js-mob-menu-btn', function () {
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            $('.js-user-menu-container').removeClass("active");
        } else {
            $(this).addClass("active");
            $('.js-user-menu-container').addClass("active");
        }
    });

    $(document).on('click', '.js-notification-preloaded', function () {
        $(this).addClass('preloaded');
    });

    // Follow/unfollow
    $(document).on('click', '.follows-container a.follow-user', function () {
        var $container = $(this).parents('.follows-container');
        $.ajax({
            'url': $(this).attr('data-href'),
            'type': 'get',
            'success': function (data) {
                $container.html(data);
            },
            'error': function (request, status, error) {
                alert('Error: Could not perform action.');
            }
        });

        return false;
    });

    $(document).on('click', '.push_notification_close', function () {
        $('.push_notification').css('bottom', '0px').animate({ bottom: '-200px' }, 500, 'linear');
        if ($('.push_notification').data('id') != null) {
            var viewed = setViewedOrders($('.push_notification').data('id'));
            socket.emit('data', viewed);
        }
        clearTimeout(notificationTimerID);
    });

    $(document).on('mouseover', '.push_notification', function () {
        clearTimeout(notificationTimerID);
        notificationsHideTimer();
    });
    notificationsHideTimer();

    if (initOnlinePush === true) {
        setInterval(function () {
            let time = new Date().getTime();
            let pushStatus = true;
            let onlinePushLastTime = getCookie('onlinePushLastTime');

            if (onlinePushLastTime !== null) {
                if (time - onlinePushLastTime < 250000) {
                    pushStatus = false;
                }
            }

            if (pushStatus) {

                setCookie('onlinePushLastTime', time);
                $.ajax({
                    type: "GET",
                    url: "/user-online/online"
                });
            }
        }, 10000);
    }
    //
    //

    /*if(onlinePushLastTime === null){
        setCookie('onlinePushLastTime');
        push = true;
    }*/

    //if (initOnlinePush === true) {
    /*setInterval(function(){
        $.ajax({
            type: "GET",
            url: "' . \yii\helpers\Url::to(['user-online/online']) . '"
        });
    }, 240000);
    ');*/
    //}


});

if ($.fn.modalmanager && $('a.ajax-modal').length) {

    $.fn.modalmanager.defaults.spinner =
        '<div class="loading-spinner" style="width: 200px; margin-left: -100px;">' +
        '<div class="progress progress-striped active">' +
        '<div class="progress-bar" style="width: 100%;"></div>' +
        '</div>' +
        '</div>';

    var $modal = $(document.createElement('div'))
        .attr('id', 'ajax-modal')
        .addClass('modal black fade')
        .css('display', 'none')
        .appendTo('body');

    $('body').on('click', 'a.ajax-modal', function () {
        $('.modal').modal('hide');
        var $a = $(this);
        if (!$a.data('confirm') || confirm($a.data('confirm'))) {

            if ($a.attr('href') !== undefined) {
                let link = $a.attr('data-href');
                setLinkToModal(link)
            } else {
                let link = $a.attr('href');
                setLinkToModal(link)
            }

            function setLinkToModal(link) {
                $modal.load(link, {}, function (response, status, xhr) {
                    if ($(response).data('parent-class') !== undefined) {
                        $modal.addClass($(response).data('parent-class'));
                    }
                    $modal.modal();
                    regionModalDecor();
                });
            }
        }

        return false;
    });
}

$(document).on('click', '.view-password', function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    const target = $($(this).attr('data-target'));
    if ($(this).hasClass('active')) {
        const fakeInput = $('<input type="text">').val(target.val()).attr('class', target.attr('class')).attr('placeholder', target.attr('placeholder')).attr('autocomplete', 'off');
        target.replaceWith(fakeInput);
        fakeInput.attr('id', target.attr('id')).prop('name', target.prop('name'));
    } else {
        const passwordInput = $('<input type="password">').val(target.val()).attr('class', target.attr('class')).attr('placeholder', target.attr('placeholder')).attr('autocomplete', 'off');
        target.replaceWith(passwordInput);
        passwordInput.attr('id', target.attr('id')).prop('name', target.prop('name'));
    }
});


function checkModalSize(scrollElement) {
    var pos = $(scrollElement).scrollTop() + $(scrollElement).outerHeight()

    if ($(scrollElement).scrollTop() > 0) {
        $(scrollElement).addClass('faded-top')
    } else {
        $(scrollElement).removeClass('faded-top')
    }

    if (pos + 1 < $(scrollElement).prop('scrollHeight')) {
        $(scrollElement).addClass('faded')
    } else {
        $(scrollElement).removeClass('faded')
    }
}

$(document).on('click', function (event) {
    const $target = $(event.target);
    const isDropdownParent = $target.closest('.js-custom-dropdown-parent').length;
    const isDropdown = $target.hasClass('js-custom-dropdown');

    if (!isDropdownParent && !isDropdown) {
        const dropdownParent = $('.js-custom-dropdown-parent');
        const dropdown = $('.js-custom-dropdown');
        const dropdownMenu = $('.js-custom-dropdown-menu');

        if (dropdown.hasClass('js-drop-with-bg-wrap')) {
            if ($(window).width() > 768) {
                $('.background-wrapper--custom-dropdown').removeClass('active');
            }
        }
        $('.user-menu__list').removeClass('active');
        dropdown.removeClass('active');
        dropdownMenu.removeClass('open');
        dropdownParent.removeClass('open');
        dropdownMenu.slideUp(300);
    }
});

$(document).on('click', '.js-custom-dropdown', function () {
    const $this = $(this);
    const dropdownParent = $this.parents('.js-custom-dropdown-parent');
    const dropdownMenu = dropdownParent.find('.js-custom-dropdown-menu');

    if ($this.hasClass('active')) {
        if ($this.hasClass('js-drop-with-bg-wrap')) {
            $('.background-wrapper--custom-dropdown').removeClass('active');
        }
        $this.removeClass('active');
        dropdownMenu.removeClass('open');
        dropdownParent.removeClass('open');
        dropdownMenu.closest('.user-menu__list').removeClass('active');
        dropdownMenu.slideUp(300);
    } else {
        const otherDropdown = $('.js-custom-dropdown.active');
        const otherDropdownParent = otherDropdown.parents('.js-custom-dropdown-parent');
        const otherDropdownMenu = otherDropdownParent.find('.js-custom-dropdown-menu');

        // Закрыть предыдущее подменю
        otherDropdown.removeClass('active');
        otherDropdownMenu.removeClass('open');
        otherDropdownParent.removeClass('open');
        otherDropdownMenu.closest('.user-menu__list').removeClass('active');
        otherDropdownMenu.slideUp(300);

        if (dropdownMenu.length > 0) {
            if ($this.hasClass('js-drop-with-bg-wrap')) {
                if ($(window).width() > 768) {
                    $('.background-wrapper--custom-dropdown').addClass('active');
                }
            }

            dropdownMenu.closest('.user-menu__list').addClass('active');
            $this.addClass('active');
            dropdownParent.addClass('open');
            dropdownMenu.slideDown(300);
        }
    }
});


$(document).on('keyup', '.input-number', function (e) {
    var amount = (parseInt($(this).val())).toFixed(2)
    this.value = number_format(amount)
})



$(document).on('click', 'a.preventDefault', function (e) {
    e.preventDefault()
})

$(document).on('click', 'a.preventDefaultMobile', function (e) {
    if (adaptive) {
        e.preventDefault()
    }
})

$(document).on('click', '.custom-select-wrap', function () {
    var list = $(this).find('.options-list')
    if (list.prop('scrollHeight') >= list.innerHeight() + 1) {
        list.addClass('faded')
    } else if (list.scrollTop() > 0) {
        list.addClass('faded-top')
    }

    list.scroll(function () {
        var pos = $(this).scrollTop() + $(this).outerHeight()

        if ($(this).scrollTop() > 0) {
            $(this).addClass('faded-top')
        } else {
            $(this).removeClass('faded-top')
        }

        if (pos + 1 < $(this).prop('scrollHeight')) {
            $(this).addClass('faded')
        } else {
            $(this).removeClass('faded')
        }
    });
})

$(document).on('click', '*[data-tag-tracking]', function () {
    tagTracking($(this).attr('data-tag-tracking'), $(this).attr('data-info'));
});

$(document).on('click', '.disable', function (e) {
    e.preventDefault()
});

$(document).on('change', '#footer-modal select[name="region_id"]', function () {
    window.location.href = '?region_id=' + $(this).val();
});

$(document).on('mouseenter', '.custom-alerts', function () {
    $(this).addClass('showAlert');
});

$(document).on('mouseleave', '.custom-alerts', function () {
    setTimeout(()=>{
        $(this).find('.alert').css('bottom', '-100px');
    },500)
    setTimeout(function () {
        $(this).removeClass('showAlert');
        $(this).remove();
    }, 2000);
});