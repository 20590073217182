/* Import common part css && scss */
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/v4-shims.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'jschr-bootstrap-modal/css/bootstrap-modal-bs3patch.css';
import 'jschr-bootstrap-modal/css/bootstrap-modal.css';
import 'jasny-bootstrap/dist/css/jasny-bootstrap.css';
import 'bootstrap-editable/css/bootstrap-editable.css';
import 'bootstrap-tagsinput/src/bootstrap-tagsinput.css';
import 'bootstrap-fileinput/css/fileinput.css';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';

import $ from 'jquery';
import moment from 'moment';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
window.moment = moment;

require('bootstrap');

require('../../../../vendor/yiisoft/yii2/assets/yii');
require('../../../../vendor/yiisoft/yii2/assets/yii.validation');
require('../../../../vendor/yiisoft/yii2/assets/yii.activeForm');
require('../../../../vendor/yiisoft/yii2/assets/yii.gridView');
require('../../../../vendor/bower-asset/yii2-pjax/jquery.pjax');

require('lazyloadxt/dist/jquery.lazyloadxt');
require('jschr-bootstrap-modal/js/bootstrap-modalmanager');
require('jschr-bootstrap-modal/js/bootstrap-modal');
require('jquery-mousewheel');
require('jquery-countdown');
require('malihu-custom-scrollbar-plugin');
require('../plugins/custom-select');
require('../plugins/custom-alert');
require('../plugins/chat');
require('../plugins/CaseChat');
require('../plugins/FileUploader');
require('../parts-custom/tabsIndicator');
require('../parts-custom/notificator');
const {Howl, Howler} = require('howler');

/* Import common part js */
require('../parts/common.js');

/* Import common part css */
import '../../scss/common.scss';
