(function ($) {
    let initCountCustomSelect = 0;
    let hasSearch = 0;
    let PluginOptions = {
      'search': function (el, clearSearch) {
        let lengthStr = parseInt(el.val().length);
        if (lengthStr > 0) {
          $('.custom-select-wrap.active .fa-times-circle').show();
          let currenVal = el.val().toLowerCase();
          let countResult = $('.custom-select-wrap.active .options-list li').length
          $('.custom-select-wrap.active .options-list li').each(function () {
            if (
              $(this).text().toLowerCase().trim().indexOf(currenVal) === 0 ||
              (typeof $(this).attr('data-search') !== 'undefined' &&
                $(this).attr('data-search').toLowerCase().trim().indexOf(currenVal) === 0)
            ) {
              $(this).show();
            } else {
              $(this).hide();
              countResult = countResult - 1 
            }
          });
          if(countResult == 0){
            $('.custom-select-wrap.active .empty').show();
            $('.custom-select-wrap .with-search').addClass('empty-text');
          } else {
            $('.custom-select-wrap.active .empty').hide();
            $('.custom-select-wrap .with-search').removeClass('empty-text');
          }
        } else {
          clearSearch();
        }
      },
    };

    jQuery.fn.customSelect2 = function (options) {
      if (typeof options === 'undefined' || Object.keys(options).length === 0) {
        options = PluginOptions;
      } else {
        if (typeof options === 'object') {
          for (let key in options) {
            if (PluginOptions.hasOwnProperty(key)) {
              PluginOptions[key] = options[key];
              if(key == 'search') {
                  PluginOptions.hasOwnProperty(key);
                  hasSearch = 1;
              }
            }
          }
        }
      }

    let init = function () {

            //if(!$(this).is('.disabled')){
                if (!$(this).parents('.custom-select-wrap').is('div')) {
                    $(this).wrap("<div class='custom-select-wrap' tabindex='0'></div>");
                }

                let wrap = $(this).parents('.custom-select-wrap');

                if($(this).is('.disabled')){
                    wrap.addClass('disabled');
                }

                $(wrap).find('.fake-select').remove();
                $(wrap).find('.options-list-wrap').remove();

                let option = $(this).find('option:selected');
                let additionVal = '';
                if (typeof $(option).attr('data-addition') !== "undefined") {
                    additionVal = '<span class="addition">' + $(option).attr('data-addition') + '</span>';
                }
                let selectVal = '<div class="sub-value">' + '<div>'+$(option).text()+'</div>' + additionVal + '</div>';

                if (typeof $(option).attr('data-icon') !== "undefined") {
                    selectVal = '<img src="' + $(option).attr('data-icon') + '" alt="">' + selectVal;
                }
                let optionsStr = '';
                $(this).find('option').each(function () {
                    let value = $(this).html();
                    let additionVal = '';
                    let additionSearch ='';
                    if (typeof $(this).attr('data-addition') !== "undefined") {
                        additionVal = '<span class="addition">' + $(this).attr('data-addition') + '</span>';
                    }
                    if (typeof $(this).attr('data-addition-search') !== "undefined") {
                        additionSearch = 'data-search="'+$(this).attr('data-addition-search')+'"';
                    }
                    optionsStr += '<li '+additionSearch+' data-letter="' + value.substr(0, 1).toLowerCase() + '" value="' + $(this).attr('value') + '"><span class="select-li-text"><div class="value">';
                    if (typeof $(this).attr('data-icon') !== "undefined") {
                        optionsStr += '<img src="' + $(this).attr('data-icon') + '" alt="">';
                    }
                    optionsStr += '<div class="sub-value">' + '<div>'+value+'</div>' + additionVal + '</div>';
                    optionsStr += '</div></span></li>';
                });

                let valueClass = option.length && $(this).val() ? 'chosen':'default';
                $(this).after("<div class='fake-select'><div class='value "+valueClass+"'></div><span class='ico-arrow'></span></div>");

                if ($(this).attr('data-input-search') === 'true' && $(this).find('option').length > 3) {
                    let emptyText = $(this).attr('data-empty')
                    if (!emptyText){
                        emptyText = ''
                    }
                    $(this).after("<div class='options-list-wrap with-search'>" +
                        "<input class='search-input' placeholder='" + customSelectLang.searchLabel + "'>" +
                        "<i class='far fa-times-circle'></i>" +
                        "<ul class='options-list'>" + optionsStr + "</ul></div><div class='empty'>" + emptyText + "</div>"
                    );
                } else {
                    $(this).after("<div class='options-list-wrap'><ul class='options-list'>" + optionsStr + "</ul></div>");
                }

                $(this).hide();
                $(this).parents('.custom-select-wrap').find('.fake-select .value').html(selectVal);
                $(this).parents('.custom-select-wrap').find('.options-list li[value="' + $(this).val() + '"]').addClass('active');

                if($(wrap).find('.options-list li').length<2){
                    $(wrap).addClass('single-list')
                }else{
                    $(wrap).removeClass('single-list')
                }

            //}
        };

        initCountCustomSelect += 1;

        if (initCountCustomSelect === 1) {
            function clearSearch() {
                $('.custom-select-wrap .search-input').val('');
                $('.custom-select-wrap .fa-times-circle').hide();
                $('.custom-select-wrap .empty').hide();
                $('.custom-select-wrap .with-search').removeClass('empty-text');
                $('.custom-select-wrap .options-list li').show();
            }

            $(document).on('click', '.fake-select', function () {
                let wrap = $(this).parents('.custom-select-wrap')
                if (wrap.is('.disabled')) {
                    // if (wrap.is('.disabled') || wrap.is('.single-list')) {
                        return
                    // }
                }

                if (!$(this).parents('.custom-select-wrap').is('.active')) {
                    $('.custom-select-wrap').removeClass('active');
                    $('select').trigger('customSelect2Close');

                    let lenghtOptions = $(this).parents('.custom-select-wrap').find('select option').length;

                    if(lenghtOptions > 1 || hasSearch == 1){
                        $(this).parents('.custom-select-wrap').addClass('active');
                        $(this).parents('.custom-select-wrap').find('select').trigger('customSelect2open')
                        let start = parseInt($($(this).parents('.custom-select-wrap')).find('.options-list').scrollTop());
                        let offsetLi = 0;
                        if($($(this).parents('.custom-select-wrap')).find('.options-list li').is('.active')){
                            offsetLi = parseInt($($(this).parents('.custom-select-wrap')).find('.options-list li.active').position().top);
                        }
                        $($(this).parents('.custom-select-wrap')).find('.options-list').scrollTop(start + offsetLi);
                    }

                } else {
                    $('.custom-select-wrap').removeClass('active');
                    $('select').trigger('customSelect2Close');
                }
            });

            $(document).on('click', '.custom-select-wrap .options-list li', function () {

                $('.custom-select-wrap').removeClass('active');
                let select = $(this).parents('.custom-select-wrap').find('select');
                $(select).val($(this).attr('value')).change();
                $(select).trigger("customSelect2Change");

                let option = $(select).find('option:selected');
                let additionVal = '';
                if (typeof $(option).attr('data-addition') !== "undefined") {
                    additionVal = '<span class="addition">' + $(option).attr('data-addition') + '</span>';
                }
                let selectVal = '<div class="sub-value">' + '<div>'+$(option).text()+'</div>' + additionVal + '</div>';

                if (typeof $(option).attr('data-icon') !== "undefined") {
                    selectVal = '<img src="' + $(option).attr('data-icon') + '" alt="">' + selectVal;
                }
                $(this).parents('.custom-select-wrap').find('.fake-select .value').removeClass('default').addClass('chosen');
                $(this).parents('.custom-select-wrap').find('.fake-select .value').html(selectVal);
                $(this).parents('.custom-select-wrap').find('.options-list li').removeClass('active');
                clearSearch();
                $(this).addClass('active');
            });

            $(document).on('focusout', '.custom-select-wrap.active', function () {

                setTimeout(function () {
                    if (!$(':focus').is('.search-input') && !$(':focus').is('.custom-select-wrap.active')) {
                        clearSearch();
                        $('.custom-select-wrap').removeClass('active');
                        $('select').trigger('customSelect2Close');
                    }
                }, 1);
            });

            $(document).on('click', '.custom-select-wrap.active .fa-times-circle', function () {
                clearSearch();
            });

            $(document).on('keyup', '.custom-select-wrap.active .search-input', function (event) {
                PluginOptions.search($(this), clearSearch);
            });

            $(document).on('keydown', function (event) {
                let activeSelect;

                if ($(':focus').is('.custom-select-wrap')) {

                    if ($('.custom-select-wrap:focus').is('.disabled')){
                        return
                    }

                    if (event.keyCode === 40
                        || event.keyCode === 38
                        || event.keyCode === 37
                        || event.keyCode === 39
                        || event.keyCode === 13
                        || event.keyCode === 32
                        || event.keyCode >= 1040 && event.keyCode <= 1105
                        || event.keyCode >= 49 && event.keyCode <= 222 && event.keyCode !== 220
                    ) {
                        if ($('.custom-select-wrap:focus .fake-select').is('div')) {
                            event.preventDefault();
                            activeSelect = $('.custom-select-wrap:focus .fake-select');
                        }
                        if ($('.custom-select-wrap').is('.active')) {
                            event.preventDefault();
                            activeSelect = $('.custom-select-wrap.active .fake-select');
                        }

                        let custom_select_wrap = $(activeSelect).parents('.custom-select-wrap');

                        if (typeof $(activeSelect) !== "undefined") {
                            let select = $(activeSelect).parents('.custom-select-wrap').find('select');

                            if (event.keyCode === 38 || event.keyCode === 37) { // Вверх/Влево
                                $(select).find('option:selected').prev().prop('selected', true);
                                $(select).change();
                            }

                            if (event.keyCode === 40 || event.keyCode === 39) { // Вправо/Вниз
                                $(select).find('option:selected').next().prop('selected', true);
                                $(select).change();
                            }

                            if (event.keyCode === 13) { // Enter
                                $('.custom-select-wrap.active').removeClass('active');
                                $('select').trigger('customSelect2Close');
                                $(select).change();
                                $(select).trigger("customSelect2Change");
                            }

                            if (event.keyCode === 32) { // Пробел
                                $(select).find('option:selected').next().prop('selected', true);
                            }

                            if (event.keyCode >= 1040 && event.keyCode <= 1105
                                || event.keyCode >= 49 && event.keyCode <= 222 && event.keyCode !== 220
                            ) {
                                let value = $(custom_select_wrap).find('.options-list li[data-letter="' + event.key.toLowerCase() + '"]').attr('value');
                                $(select).find('option[value="' + value + '"]').prop('selected', true);
                            }

                            let option = $(select).find('option:selected');
                            let additionVal = '';
                            if (typeof $(option).attr('data-addition') !== "undefined") {
                                additionVal = '<span class="addition">' + $(option).attr('data-addition') + '</span>';
                            }
                            let selectVal = '<div class="sub-value">' + '<div>'+$(option).text()+'</div>' + additionVal + '</div>';

                            if (typeof $(option).attr('data-icon') !== "undefined") {
                                selectVal = '<img src="' + $(option).attr('data-icon') + '" alt="">' + selectVal;
                            }

                            $(custom_select_wrap).find('.fake-select .value').removeClass('default').addClass('chosen');
                            $(custom_select_wrap).find('.fake-select .value').html(selectVal);
                            $(custom_select_wrap).find('.options-list li').removeClass('active');
                            $(custom_select_wrap).find('.options-list li[value="' + $(select).val() + '"]').addClass('active');

                            let start = parseInt($(custom_select_wrap).find('.options-list').scrollTop());
                            let offsetLi = parseInt($(custom_select_wrap).find('.options-list li.active').position().top);
                            $(custom_select_wrap).find('.options-list').scrollTop(start + offsetLi);
                        }
                    }
                }
            });
        }

        return this.each(init);
    };
})(jQuery);