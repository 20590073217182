import $ from 'jquery';
import moment from 'moment';
import 'moment-timezone';
// Для русского языка, добавьте другие языки по необходимости
import 'moment/locale/ru';

function CaseChat(containerSelector, formSelector, options = {}) {
    this.containerSelector = containerSelector;
    this.container = $(this.containerSelector);
    this.formSelector = formSelector;
    this.backendUrl = options.backendUrl;
    this.dataId = this.container.data('id');
    this.currentLanguage = options.language || 'en';
    this.serverTimezone = "Etc/GMT";
    this.clientTimezone = "Etc/GMT-3";
    moment.locale(phpLocale); // ugly but working atm :/
    const formatter = new Intl.DateTimeFormat(this.currentLanguage);
    const dateSample = formatter.format(new Date());

    if (dateSample.includes('.')) {
        this.dateFormat = 'DD.MM.YYYY';
    } else {
        this.dateFormat = 'D MMM YYYY';
    }

    this.init();
}

CaseChat.prototype.init = function() {
    this.container = $(this.containerSelector);

    const self = this;
    $(document).on('submit', self.formSelector, function(e) {
        e.stopPropagation();
        e.preventDefault();
        const input = $(this).find('#user-input');
        const messageText = input.val().trim();

        if (messageText.length) {
            const messageElement = self.createMessageElement(messageText);
            self.displayMessage(messageElement);
            self.scrollToBottom();

            const formData = new FormData($(this)[0]);

            formData.append('body', messageText);

            self.triggerMessageSent();

            self.sendMessage(formData, messageElement);

            input.trigger('change')

            input.val('');  // Clear the input field
        }

        return false;
    });
};

CaseChat.prototype.createMessageElement = function(text, status = 'sending') {
    const userAvatarUrl = $("#user-avatar-url").val() || '/img/default_user_active.svg';
    text = stripHtml(text).replace(/\n/g, '<br>');
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    const messageElement = $('<div>', {
        class: `timeline-message timeline-item owner ${status} fade-in`,
        'data-ts': currentTimestamp
    });

    const currentTime = moment();
    const messageTime = currentTime.clone().tz(this.serverTimezone).format('HH:mm');
    const displayDate = currentTime.clone().tz(this.serverTimezone).format(this.dateFormat);

    const messageBodyContent = $('<div>', { class: 'timeline-body-content' })
        .append($('<p>', { class: 'timeline-body-message' }).html(text))
        .append(
            $('<span>', { class: 'timeline-body-time', title: messageTime })
                .append($('<span>', { class: 'message-indicator message-unread' }))
                .append(document.createTextNode(` ${messageTime}`))
        );

    messageElement.append(
        $('<div>', { class: 'timeline-head' }).append(`<img class="timeline-badge-userpic" src="${userAvatarUrl}">`),
        $('<div>', { class: 'timeline-body' }).append(messageBodyContent)
    );

    return messageElement;
};

CaseChat.prototype.displayMessage = function(messageElement) {
    this.container = $(this.containerSelector);
    const timelineContainer = this.container.find('.timeline');
    const emptyBlock = this.container.find('.empty');

    if(emptyBlock.length) {
        emptyBlock.hide();
    }
    const lastTimelineGroup = timelineContainer.children('.timeline-group').last();

    const currentUTCDate = moment.utc();
    const formattedDate = currentUTCDate.format(this.dateFormat);
    currentUTCDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const currentTimestamp = currentUTCDate.unix();

    const lastGroupTimestamp = parseInt(lastTimelineGroup.data('ts'), 10) || 0;

    if (lastGroupTimestamp === currentTimestamp) {
        lastTimelineGroup.append(messageElement);
    } else {
        const newTimelineGroup = $('<div>', {
            class: 'timeline-group timeline-item',
            'data-ts': currentTimestamp
        })
            .append($('<div>', { class: 'timeline-date-header' }).text(formattedDate), messageElement);
        timelineContainer.append(newTimelineGroup);
    }

    messageElement.hide().fadeIn(500);

    timelineContainer.scrollTop(timelineContainer[0].scrollHeight);
};


CaseChat.prototype.scrollToBottom = function() {
    const chatContent = this.container.find('.timeline');
    const chatHeight = chatContent[0].scrollHeight;
    chatContent.scrollTop(chatHeight);
};

CaseChat.prototype.sendMessage = function(formData, messageElement) {
    const self = this;

    $.ajax({
        url: self.backendUrl + '?id=' + self.dataId,
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: function(response) {
            if (response.success) {
                self.triggerMessageDelivered();
                const updatedElement = self.createMessageElement(formData.get('body'), 'sent');
                messageElement.replaceWith(updatedElement);
            } else {
                self.triggerMessageSendFailed();
                const updatedElement = self.createMessageElement(formData.get('body'), 'failed');
                messageElement.replaceWith(updatedElement);
            }
        },
        error: function() {
            const updatedElement = self.createMessageElement(formData.get('body'), 'failed');
            messageElement.replaceWith(updatedElement);
        }
    });
};

function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

function stripHtml(html) {
    return $("<div>").html(html).text();
}

export function initializeChat(containerSelector, formSelector, options) {
    $(document).off('submit', formSelector);
    const chatInstance = new CaseChat(containerSelector, formSelector, options);
    return chatInstance;
}

// Events
CaseChat.prototype.triggerMessageSent = function() {
    this.container.trigger("messageSent");
};

CaseChat.prototype.triggerMessageDelivered = function() {
    this.container.trigger("messageDelivered");
};

CaseChat.prototype.triggerMessageSendFailed = function() {
    this.container.trigger("messageSendFailed");
};

export default CaseChat;
